import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {ProjectConfig} from 'Global';
import Cookies from 'universal-cookie';
import ic_empty from 'icons/ic_empty.svg';
import RefreshIcon from '@mui/icons-material/Refresh';
import BlockDetails from './BlockDetails';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';

const cookies = new Cookies();

export default function AmazonLogs(props: any) {
  
  const {
    setLoading, 
    activeAccount,
    isReseller
  } = props;

  const currentPage= 1;


  // BLOCKS LIST
  const [list, setList] = useState<any []>([]);
  const [total_pages_blocks, setTotalPagesBlocks] = useState(0);
  const [pageLog, setPageLog] = useState(currentPage);

  const getBlocksLog = () => {
    setLoading(true)
    axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'get_blocks_log',
          params: {
            page: pageLog,
            activeAccount: activeAccount,
            isReseller: isReseller
          },
        }
      })
        .then((response) => {
          console.log(response.data);
          setList(response.data.list)
          setTotalPagesBlocks(response.data.total_pages)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('pageLog', pageLog.toString())
  }

  useEffect(() => {
    getBlocksLog();
  }, [pageLog, activeAccount])

  useEffect(() => {
    setPageLog(1);
  }, [activeAccount])


  const [openBlockDetails, setOpenBlockDetails] = useState(false);
  const [activeItem, setActiveItem] = useState(0);

  return (
    <div id='blockListBoxContainer' className='boxContainer'>

        <div className='boxContainerHeader'>
          <div className='boxContainerTitle'>
            <h2 className='h2_title'>Activity</h2>
            <span className='h2_subtitle'>Log of account actions</span>
          </div>
          <div className='boxContainerActions'>
            <Button
              variant="contained"
              onClick={()=> {getBlocksLog()}}
              startIcon={<RefreshIcon />} 
            >Refresh</Button>
          </div>
        </div>
      <Box id='blocksList'>


      {list.length > 0 ? (
        <Table>
            <TableHead>
            <TableRow>
                <TableCell>Event time</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Total time</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Price</TableCell>
                <TableCell></TableCell>
                
                
                
            </TableRow>
            </TableHead>
            <TableBody>
                {list.map((log,i) => (

                  
                <TableRow
                    key={i}
                    hover
                    role="checkbox"
                >
                  {log.type_log === 'offer' ?
                  (
                    <>
                      <TableCell>
                        
                        <span className='td_column_title'>{
                          // if is today show 'Today' else show date
                          log.created_at === "0000-00-00 00:00:00"? '-' :
                          moment(log.created_at).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? 'Today' : moment(log.created_at).format('MMM DD / YYYY')
                          }</span>
                        <span className='td_column_subtitle'>
                          {
                            // if is today show time else show date
                            log.created_at === "0000-00-00 00:00:00"? '' : moment(log.created_at).format('hh:mm A')
                          }
                          
                          </span>
                      </TableCell>
                      <TableCell className='td_column'>
                        <span className='td_column_title'>{log.email}</span>
                        <span className='td_column_subtitle'>{log.serviceAreaName}</span>
                      </TableCell>

                      <TableCell>
                        <Chip label={log.offer_status} color="primary" variant='outlined' size='small' />
                      </TableCell>

                      <TableCell>{log.total_hours} H</TableCell>

                      <TableCell>
                      <div className='bd_body_section'>
                        <div className='bd_body_date'>
                          <span className='bd_body_label td_column_title'>Start</span>
                          <span className='bd_body_value'>{log.start_date}</span>
                          <span className='bd_body_label td_column_title'>{log.start_time}</span>
                        </div>

                        <div className='bd_body_arrow'>
                          <ArrowForwardIosIcon color='primary' />
                        </div>

                        <div className='bd_body_date alignRight'>
                          <span className='bd_body_label td_column_title'>End</span>
                          <span className='bd_body_value'>{log.end_date}</span>
                          <span className='bd_body_label td_column_title'>{log.end_time}</span>
                        </div>

                      </div>
                      </TableCell>

                      <TableCell>$ {log.price_amount.toLocaleString()}</TableCell>
                      <TableCell className='alignRight'>
                        <Button variant="contained" color="primary" size="small" onClick={()=>{
                          setOpenBlockDetails(true);
                          setActiveItem(log.id);
                          }}>Details</Button>
                      </TableCell>
                    </>
                  ) : (
                    <>
                    {/* 'created_at' => $created_at,
                    'serviceAreaName' => $serviceAreaName,
                    'type_log' => $type_log,
                    'log_message' => $log_message,
                    'log_type' => $log_type */}
                      <TableCell>
                        <span className='td_column_title'>{
                          // if is today show 'Today' else show date
                          log.created_at === "0000-00-00 00:00:00"? '-' :
                          moment(log.created_at).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? 'Today' : moment(log.created_at).format('DD/MM/YYYY')
                          }</span>
                        <span className='td_column_subtitle'>
                          {
                            // if is today show time else show date
                            log.created_at === "0000-00-00 00:00:00"? '' : moment(log.created_at).format('hh:mm A')
                          }
                          </span>
                      </TableCell>
                      <TableCell colSpan={6}>
                        <span className={"status_log log_"+log.log_type}>{log.log_message}</span>
                      </TableCell>
                    </>
                  )
                  }


                      
                </TableRow>
                ))}
            </TableBody>
        </Table>
      ) : (
        <div className="noProducts">
              <img src={ic_empty} alt=""/>
              <p>Nothing to show here</p>
            </div>
      )}
      {total_pages_blocks > 1 && (
        <Pagination 
            count={total_pages_blocks} page={pageLog}  onChange={(event, value) => {
            setPageLog(value)}} 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
        />
      )}
      
        
      </Box>

      <BlockDetails 
        open={openBlockDetails} 
        setOpen={setOpenBlockDetails} 
        setLoading={setLoading} 
        blockId={activeItem}
        
      />


    </div>
  );
};

