import Cookies from "universal-cookie";

import * as React from "react";
import {
  Link,
  Avatar,
  Button,
  Container,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import axios from "axios";
import { useSnackbar, withSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { resolve } from "path";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import FormLogin from "../FormLogin";
import FormChangePass from "../FormChangePass";
import FormCredentialsSent from "../FormCredentialsSent";
import FormForgotPass from "../FormForgotPass";

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LanguageIcon from "@mui/icons-material/Language";
import "./landing.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";

import top_curve from "./top_curve.svg";
import ic_flag from "./ic_flag.svg";
import ic_bell from "./ic_bell.svg";
import ic_world from "./ic_world.svg";

import smartphone1 from "./smartphone1.png";
import smartphone2 from "./smartphone2.png";
import cc_brands from "./cc_brands.png";

import ic_whatsapp from "./ic_whatsapp.svg";
import ic_telegram from "./ic_telegram.svg";
import ic_email from "./ic_email.svg";
import ic_phone from "./ic_phone.svg";
import city_bg from "./city_bg.png";
import logo from "./logo.png";
import bottom_curve from "./bottom_curve.svg";



const cookies = new Cookies();

const windowHeight = window.innerHeight;

export function Landing(props: any) {
  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const [state, setState] = useState("login");
  const [userEmail, setUserEmail] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setLoading(false);
    setEmail(searchParams.get("email") || "");
    setCode(searchParams.get("code") || "");
  }, []);

  useEffect(() => {
    if (email && code) {
      setState("changePass");
    }
  }, [code]);

  const [loadingButton, setLoadingButton] = useState(false);

  const defaultValues: any = {
    name: "",
    lastname: "",
    email: "",
    password: "",
    password_confirmation: "",
  };

  const [formValues, setFormValues] = React.useState(defaultValues);

  const [openRegister, setOpenRegister] = React.useState(false);

  const setLoading = props.setLoading;

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setFormValues(defaultValues);
  }, [openRegister]);

  //IF TOKEN MOBILE APP EXISTS IN URL
  const tokenApp = localStorage.getItem("tokenApp")
    ? localStorage.getItem("tokenApp")
    : "";

  /*================================================
  ================================================*/

  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <>
      
    <div id='landing'>
      <div id="home"></div>
      <div
        id="section_1_wrapper"
        style={{ backgroundImage: `url(${city_bg})` }}
      >
        <nav>
        <a href="#home">Home</a>
        <a href="#features">Features</a>
        <a href="#pricing">Pricing</a>
        <a href="#contact">Contact</a>
      </nav>
        
        <div className="overlay"></div>
        <div id="section_1">
          <div className="divcenter">
            <div className="section_left">
              <h1>The most complete hunting system on the market.</h1>
              <p>Easyflexdriver.com captures blocks of increased value. Register for only $47 dollars land get 10 days
              </p>
            </div>

            <div className="loginDiv">
              {state === "login" && (
                <FormLogin
                  setLoading={setLoading}
                  setState={setState}
                  email={userEmail}
                  setEmail={setUserEmail}
                />
              )}
              {state === "forgotPass" && (
                <FormForgotPass
                  setLoading={setLoading}
                  setState={setState}
                  email={userEmail}
                  setEmail={setUserEmail}
                />
              )}
              {state === "credentialsSent" && (
                <FormCredentialsSent
                  setState={setState}
                  setEmail={setUserEmail}
                />
              )}
              {state === "changePass" && (
                <FormChangePass
                  setLoading={setLoading}
                  setState={setState}
                  email={email}
                  code={code}
                />
              )}
            </div>
          </div>
        </div>

        <img src={top_curve} alt="Top curve" className="top_curve" />
      </div>


      <div id="features"></div>
      <div id="section_2" className="section">
        <div className="divcenter">
          <div className="section_2_left">
            <h3>Choose, filter, and schedule</h3>
            <h2>
              The value and time you <span className="text_blue">want</span> to{" "}
              <span className="text_red">work!</span>
            </h2>
            <span className="subtitle">
              The most complete hunting system on the market.
            </span>

            <div id="featuresContainer">
              <div className="feature">
                <img src={ic_flag} alt="Flag" />
                <div className="feature_text">
                  <h4>Check in</h4>
                  <p>Check in even if you haven't arrived at the station.</p>
                </div>
              </div>

              <div className="feature">
                <img src={ic_bell} alt="Bell" />
                <div className="feature_text">
                  <h4>Notifications</h4>
                  <p>
                    Do not waste any more time, we will notify you via text
                    message when the best block for you comes out.
                  </p>
                </div>
              </div>

              <div className="feature">
                <img src={ic_world} alt="World" />
                <div className="feature_text">
                  <h4>Use it everywhere</h4>
                  <p>
                    Use your desktop, laptop, or smartphone to start receiving
                    offers.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section_2_right">
            <img src={smartphone1} alt="Smartphone" />
          </div>
        </div>
      </div>


      <div id="pricing"></div>
      <div id="section_3" className="section">
        <div className="section_3_header">
          <h3>Choose the best option for your needs</h3>
          <h2>
            We handle different <span className="text_plans">plans</span> for
            you
          </h2>
        </div>

        <div className="divcenter">
          <div className="section_3_left">
            <img src={smartphone2} alt="Smartphone" className="smartphone2" />
          </div>

          <div className="section_3_right">
            <div className="plans">
              <div className="plan">
                <h3 className="blue_box">$47</h3>
                <p>1 WEEK</p>
              </div>

              <div className="plan">
                <h3 className="red_box">$87</h3>
                <p>2 WEEKS</p>
              </div>
            </div>

            <p>We accept all major credit cards</p>
            <img src={cc_brands} alt="Credit cards" className="cc_brands" />
          </div>
        </div>
      </div>

      <div id="section_4" className="section">
        <h2>Join our Referral club</h2>
        <p>
          Get free days, enjoy our benefits and be the first to get our best
          discounts.
        </p>
        <a href="#" className="button">
          Join now
        </a>
      </div>

      <div id="contact"></div>
      <div id="section_5" className="section">
        <div className="divcenter">
          <h2>Let's talk</h2>
          <div className="contact_links">
            <a
              href="https://wa.me/14075697050"
              target="_blank"
              className="contact_link"
            >
              <img src={ic_whatsapp} alt="WhatsApp" />
              <p>+1 (407)569-7050</p>
            </a>

            <a
              href="https://t.me/+14075697050"
              target="_blank"
              className="contact_link"
            >
              <img src={ic_telegram} alt="Telegram" />
              <p>+1 (407)569-7050</p>
            </a>

            <a href="mailto:admin@easyflexdriver.com" className="contact_link">
              <img src={ic_email} alt="Email" />
              <p>admin@easyflexdriver.com</p>
            </a>

            <a href="tel:+14075697050" className="contact_link">
              <img src={ic_phone} alt="Phone" />
              <p>+1 (407)569-7050</p>
            </a>
          </div>
        </div>
      </div>


          <div className="divcenter" id = "logo_footer">
            <div className="section_left">
              <img src={logo} alt="Logo" className="logo" />
            </div>

            <div>
            
            </div>
          </div>

      <div
        id="section_footer_wrapper"
        style={{ backgroundImage: `url(${city_bg})` }}
      >
        <div className="overlay"></div>
        <div id="section_footer">
          <div className="divcenter">

            <div className="socialmediaDiv">
             <h3>FOLLOW US</h3>
             <div className="social_media">
              <a href="https://www.facebook.com/easyflexdriver" target="_blank">
                <FacebookIcon />
              </a>
              <a href="https://www.instagram.com/easyflexdriver" target="_blank">
                <InstagramIcon />
              </a>
              <a href="https://t.me/easyflexdriver" target="_blank">
                <TelegramIcon />
              </a>
            </div>
            </div>
          </div>
        </div>
        

        <svg width="100%" height="669" viewBox="0 0 1920 669" preserveAspectRatio="none"  fill="none" xmlns="http://www.w3.org/2000/svg" className="bottom_curve">
<g>
<path d="M0 669L1229.72 46.9408C1392 -36.0162 1590.81 28.2865 1673.76 190.566L1920 669V0H0V669Z" fill="white"/>
</g>
</svg>


      </div>
      </div>
    </>
  );
}
