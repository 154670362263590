import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton} from '@mui/material';
import Cookies from 'universal-cookie';
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Landing } from './Landing/Landing';
import { useSearchParams } from 'react-router-dom';
import FormLogin from './FormLogin';
import FormChangePass from './FormChangePass';
import FormCredentialsSent from './FormCredentialsSent';
import FormForgotPass from './FormForgotPass';



const cookies = new Cookies();


export default withSnackbar(
  function SignIn(props: any) {

  const setLoading = props.setLoading;

  return (

          <Landing 
            setLoading={setLoading}
            />
       

    
  );
}
);