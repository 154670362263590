import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, Dialog, DialogContent, DialogContentText, Icon, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {ProjectConfig} from 'Global';
import Cookies from 'universal-cookie';
import ic_empty from 'icons/ic_empty.svg';
import RefreshIcon from '@mui/icons-material/Refresh';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSnackbar } from 'notistack';
import AlertDialog from 'elements/AlertDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SportsScoreIcon from '@mui/icons-material/SportsScore';

const cookies = new Cookies();

export default function BlockDetailsSchedule(props: any) {
  
  const {setLoading, open, setOpen, data} = props;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertCheckInOpen, setAlertCheckInOpen] = useState(false);

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */



  
/*=========================================================
  CANCEL OFFER
  =========================================================*/
  const cancelOffer = () => {
    setLoading(true);
    axios.post(ProjectConfig.api_url, null,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'cancel_offer',
          account_id: data.account_id,
          pickUpTime: data.startDateUnix,
        }
      })
        .then((response) => {
          

          enqueueSnackbar(response.data.message, { variant: response.data.status });
          setLoading(false);
           
        })
        .catch((err) => {
          console.log(err.data);
        })
  }

/*=========================================================
  CHECK IN
  =========================================================*/

  const checkIn = () => {
    setLoading(true);
    axios.post(ProjectConfig.flexapi+"/utils/checkin", data,
      {})
        .then((response) => {
          
          setLoading(false);
          console.log(response.data); 
          enqueueSnackbar(response.data.message, { variant: response.data.status });
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        .then(() => {
          setOpen(false);
        })
  }


  
  return (
    <>
    {Object.keys(data).length === 0 ? null :
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
          <DialogContent  className="forced_box_editor">

            <div className='bd_serviceAreaname'>
              {data.serviceAreaName}
              
            </div>
            
            <div className='bd_header'>
              <div className='bd_header_left'>
                <span className='bd_price_label'>Price</span>
                <span className='bd_price'>${data.price_amount}</span>
              </div>
              <div className='bd_header_right'>
                
              </div>
            </div>

            

            <div className='bd_body'>
              <div className='bd_body_section'>
                <div className='bd_body_icon'>
                <CalendarMonthIcon color='primary' />
                </div>
              </div>
              <div className='bd_body_section'>
                <div className='bd_body_date'>
                  <span className='bd_body_label'>Start</span>
                  <span className='bd_body_value'>{data.startDate.split(' ')[0]}</span>
                  <span className='bd_body_label'>{data.startDate.split(' ')[1]}</span>
                </div>

                <div className='bd_body_arrow'>
                  <ArrowForwardIosIcon color='primary' />
                </div>

                <div className='bd_body_date alignRight'>
                  <span className='bd_body_label'>End</span>
                  <span className='bd_body_value'>{data.endDate.split(' ')[0]}</span>
                  <span className='bd_body_label'>{data.endDate.split(' ')[1]}</span>
                </div>

              </div>

              <div className='bd_body_section alignRight'>
                <div>
                  <span className='bd_body_label'>Total Time</span>
                  <span className='bd_body_value'>{data.total_hours} H</span>
                </div>
              </div>

              

            </div>

          <div className="cancelButton">
            <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setAlertOpen(true)}
                  startIcon={<DeleteForeverIcon />}
              >
                  Forfeit Block
              </Button>
        
              {data.minutes_left <= 15 && data.minutes_left > -5 &&
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setAlertCheckInOpen(true)}
                    startIcon={<SportsScoreIcon />}
                >
                    Check in
                </Button>
              }
              
              {data.minutes_left > -5 &&
                <div style={{marginTop: '10px'}}>
                  {data.minutes_left > 60 ? (data.minutes_left/60).toFixed(1) : data.minutes_left} {data.minutes_left > 60 ? 'hours' : 'minutes'} left

                </div>
              }
              

          </div>
          
          

          <div className="buttons">
              <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(false)}
              >
                  Close
              </Button>
              
             

          </div>

          </DialogContent>
      </Dialog>
    }
    


      <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={cancelOffer}
        subtitle="Are you sure you want to cancel this offer?"
      />


      <AlertDialog
        open={alertCheckInOpen}
        setOpen={setAlertCheckInOpen}
        send={checkIn}
        subtitle="Are you sure you want to check in?"
      />

       
    </>
  );
};

