
import { Button } from '@mui/material';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import Box from 'elements/Box';
import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie';
import { useSnackbar } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';

const cookies = new Cookies();

export default function ResellerSettingsd (props: any) {

  const { setLoading, isMobile } = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [stripeAccountInfo, setStripeAccountInfo] = useState<any>(null);
  /*============================================= */



  useEffect(() => {
    setLoading(false);
    checkStripeAccount();
  }, []);
  
  


    /*=============================================
  SUBMIT TO STRIPE
  =============================================*/
  const submitToStripe = () => {
    setLoading(true);

    const body = new FormData();
    axios.post(ProjectConfig.flexapi+"/stripe/createAccount", null,
      {
        headers: {
          "ltkn": localStorage.getItem("ltkn"),
        },
      })
      .then((response) => {
        console.log(response.data);
        
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
  };

  /*=============================================
  CHECK STRIPE ACCOUNT
  =============================================*/
  const checkStripeAccount = () => {
    setLoading(true);
    axios.get(ProjectConfig.flexapi+"/stripe/checkAccount",
      {
        headers: {
          "ltkn": localStorage.getItem("ltkn"),
        },
      })
      .then((response) => {
        console.log(response.data);
        setStripeAccountInfo(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
  }


  const openLink = () => {
    //stripeAccountInfo.url

    //OPEN POPUP AND CHECK WHEN IT CLOSES
    const popup = window.open(stripeAccountInfo.url, "_blank", "width=600,height=600");
    const pollPopup = setInterval(() => {
      if (!popup || popup.closed) {
        clearInterval(pollPopup);
        checkStripeAccount();
      }
    }, 500);
    
  }

  
  return (

    <div id='AccountsList' className='ModuleContainer'>
      

        
        <div className='boxContainer'>

            <div className='boxContainerHeader'>
                <div className='boxContainerTitle'>
                    <h2 className='h2_title'>Settings</h2>
                    <span className='h2_subtitle'>Reseller account configuration</span>
                </div>
                <div className='boxContainerActions'>
                    
                </div>
            </div>

            {stripeAccountInfo && (
                <Box>
                    <h2 className='h2_title'>Reseller Payments</h2>
                    <span className='h2_subtitle'>Connect your Stripe account to receive payments</span>


                    {stripeAccountInfo.status === "active" && (
                        <div className='statusContainer'>
                            <CheckCircleIcon style={{ color: "green", fontSize: "30px" }} />
                            <span>Your Stripe account is active</span>
                        </div>
                    )}
                    
                    {stripeAccountInfo.status === "pending" && (
                        <div className='statusContainer'>
                            <span>Your Stripe account is pending to be activated, please click the button below and follow the instructions</span>
                            
                            <Button
                                variant="contained"
                                className="button_1"
                                style={{ marginTop: "20px" }}
                                onClick={openLink}
                                
                            >
                                Connect Stripe Account
                            </Button>
                        </div>
                    )}


                </Box>
            )}

        </div>
    </div>

    
  );
};


