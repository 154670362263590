import React from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    TextField,
    Link,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import Box from 'elements/Box';
import { ProjectConfig } from 'Global';

export default function FormForgotPass( props: any ) {
    
    const {
        loading,
        logo,
        setLoading,
        setState,
        email,
        setEmail,
    } = props;

    /*================================================
    SNACKBAR FUNCTION FOR NOTIFICATIONS
    ================================================*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */


    /*================================================
    FORGOT PASSWORD (SUBMIT)
    ================================================*/
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);
        axios.get(ProjectConfig.api_url, {
            params: {
                request: "forgot_password",
                email: data.get('email'),
            },
        })
        .then((response) => {
            if(response.data.status === "success"){
                enqueueSnackbar(response.data.message, { variant: 'success' });
                setState('credentialsSent');
            } else {
                enqueueSnackbar(response.data.message, { variant: 'error' });
            }
        })
        .catch((error) => {
            enqueueSnackbar(error.toString(), { variant: 'error' });
        })
        .finally(() => {
            setLoading(false);
        });
        setState('credentialsSent'); // REMOVE THIS LINE
    };
    /*================================================
    ================================================*/

    return(
        <Box id='loginBox'>

          <Link
            component="button"
            variant="subtitle2"
            underline="none"
            onClick={() => {
                setEmail('');
                setState('login')
            }}
            sx={{display:'flex', alignItems:'center', fontSize: 'small', justifyContent: 'start', columnGap: '5px', padding: '0px 0px 10px 0px'}}
          >
            <ArrowBack sx={{ fontSize: 'small' }} />
            Volver
          </Link>
          
          <img className='loginLogo' src={logo}></img>

          <p>Enter the email address of your account to submit the data to restore your access.</p>
          <form onSubmit={handleSubmit} className='form'>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="text"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <LoadingButton
              size="small"
              type="submit"
              loading={loading}
              fullWidth
              variant="contained"
              sx={{ fontSize: '13px', padding: '10px 0px'}}
            >
              Submit
            </LoadingButton>

          </form>
        </Box>
    );
}