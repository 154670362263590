import React, { useEffect, useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ProjectConfig } from "../../../Global";
import PaymentForm from "./PaymentForm";
import axios from "axios";
import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";


const stripePromise = loadStripe(ProjectConfig.STRIPE_PUBLIC_KEY); // public key

export function StripeComponent( props: any ) {

    const {
        onResponse,
        onCanceled,
        clientSecret = null,
    } = props;

    const [options, setOptions] = useState<any>(null);
    const [paymentResult, setPaymentResult] = useState<any>(null);

    useEffect(() => {
        if (clientSecret) {
            setOptions({
                clientSecret,
            });
        }
    }, [clientSecret]);

    useEffect(() => {
        onResponse(paymentResult);
    }, [paymentResult]);

    return (
        <>
            {options ?
                <>
                    <Elements stripe={stripePromise} options={options}>
                        <PaymentForm
                            onResult={setPaymentResult}
                            onCanceled={onCanceled}
                        />
                    </Elements>
                    { paymentResult }
                </>
            :
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <CircularProgress />
                    <p> Loading... </p>
                </div>
            }
        </>
    );

};

export default function PaymentDialog( props: any ) {

    console.log("PaymentDialog", props);
    const {
        typePayment = "card",
        open = false,
        handleOpen = () => {},
        amount = 0,
        currency = "USD",
        metadata = {},
        onResponse = null,
    } = props;
    
    const getStripeClientSecret = async (typePayment: string, amount: number,currency: string, metadata: any) => {

        const body = {
            type_payment: typePayment,
            amount,
            currency,
            metadata,
        };

        axios.post(ProjectConfig.flexapi + '/payment_gateway/stripe/init_payment', body,
            {
                headers: {
                    "ltkn": localStorage.getItem("ltkn"),
                }
            })
            .then((response) => {
                console.log(response.data);
                if(response.data.status === "success"){
                    setStripeClientSecret(response.data.data.client_secret);
                }
                else
                {
                    console.log(response.data.message);
                }
            })
            .catch((err) => {
                console.log(err.data);
            });
    }

    const [stripeClientSecret, setStripeClientSecret] = useState<string | null>(null);

    useEffect(() => {
        if (open) {
            getStripeClientSecret(typePayment, amount, currency, metadata);
        }
    }, [open])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    handleOpen(false)
                }}
            >
                <DialogTitle> Payment Dialog </DialogTitle>
                <DialogContent>
                    <StripeComponent
                        clientSecret={stripeClientSecret}
                        onResponse={(result: any) => {
                            if (onResponse && typeof result === "string") {
                                onResponse(result);
                            }
                        }}
                        onCanceled={() => {
                            handleOpen(false);
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};