import { Button,ListItemIcon,Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MenuIcon from '@mui/icons-material/Menu';

export default function AccountMenu(props: any) {

    const {
        account,
        setOpenOrders,
        setActiveItem,
        handleDelete,
        setAccountId,
        isReseller
    } = props;

    
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    




    return (
    <>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} startIcon={<MenuIcon />} variant="outlined" size="small" color="primary">
            Menu
        </Button>

        
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            
        >


            {/*===================== EDIT =====================*/}
            <MenuItem 
            onClick={() => {
                handleClose();
            }}
            component={Link}
            to={`/amazon-accounts/edit/${account.account_id}`}

            >
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                Edit
            </MenuItem>


            {/*===================== LOGS =====================*/}
            <MenuItem onClick={() => {
                setActiveItem(account);
                handleClose();
            }}>
                <ListItemIcon>
                    <RemoveRedEyeIcon fontSize="small" />
                </ListItemIcon>
                Logs
            </MenuItem>

            {/*===================== ORDERS =====================*/}
            <MenuItem onClick={() => {
                setAccountId(account.account_id);
                setOpenOrders(true);
                handleClose();
            }
            }>
                <ListItemIcon>
                    <ReceiptIcon fontSize="small" />
                </ListItemIcon>
                Orders
            </MenuItem>

            {/*===================== DELETE =====================*/}
            {!isReseller && (
                <MenuItem onClick={() => {
                    handleClose();
                    handleDelete(account);
                }}
                style={{color: 'red'}}
                >
                    <ListItemIcon>
                        <DeleteOutlineOutlinedIcon fontSize="small" color="error"/>
                    </ListItemIcon>
                    Delete Account
                </MenuItem>
            )}

        </Menu>

    </>
  );
}

