import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import Cookies from 'universal-cookie';
import EditIcon from '@mui/icons-material/Edit';
import AlertDialog from 'elements/AlertDialog';
import { useSnackbar, withSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';

const cookies = new Cookies();

export default withSnackbar(
  function ResellerList(props: any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [user_list, setUserList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    loadList();
  }, [page, refreshList])
  /*=========================================================*/

  const loadList = () => {

    setLoading(true);
  
    axios.get(ProjectConfig.flexapi+"/resellers/list",
      {
      params: {
          page: page,
      }
    })
      .then((response) => {
        console.log("response: ",response.data);
        setUserList(response.data.list)
        setTotalPages(response.data.total_pages)
        setLoading(false)
          
      })
      .catch((err) => {
        console.log(err.data);
      })
      localStorage.setItem('page', page.toString())
  }



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (user_id:number) => {

    setActiveItem(user_id);
    setAlertOpen(true);
    
  }
  /*=========================================================*/

// call global snackbar

const deleteItem = () => {

  axios.post(ProjectConfig.api_url, null, 
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
      },
      params: {
        request: 'delete_reseller',
        user_id: activeItem
      }
    })

    .then((response) => {
      console.log(response.data);

      if(response.data.status == 'success'){
        enqueueSnackbar('Reseller deleted successfully', { variant: 'success' });
      }else{
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }


      loadList();
    })
    .catch((err) => {
      console.log(err.data);
    })
}
  
const [alertOpen, setAlertOpen] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);
  
  return (
    <>
    <div id='AccountsList' className='ModuleContainer'>
      

        
      <div className='boxContainer'>

        <div className='boxContainerHeader'>
          <div className='boxContainerTitle'>
            <h2 className='h2_title'>Resellers</h2>
            <span className='h2_subtitle'>List of resellers</span>
          </div>
          <div className='boxContainerActions'>
            <Button component={Link} to="/resellers/add" variant="contained" startIcon={<AddIcon />}>
              Add Reseller
            </Button>
          </div>
        </div>
          
            <Box id='user_list'  >
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell align='right' className='td_buttons'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {user_list.map((user,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>
                        <AppAvatar name={user.name} image={user.image} />
                      </TableCell>
                      <TableCell>{user.name} {user.last_name}</TableCell>

                     
                      <TableCell>
                        {user.role_name}
                      </TableCell>

                      <TableCell align='right' className='td_buttons'> 
                        <Button
                          component={Link}
                          to={`/resellers/edit/${user.user_id}`}
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{marginRight: "10px"}}
                          startIcon={<EditIcon />}
                        >
                          Manage
                        </Button>


                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(user.user_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          {total_pages > 1 &&(
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
          )}
          </Box>
 
        </div>
    </div>

    <AlertDialog 
    open={alertOpen} 
    setOpen={setAlertOpen} 
    send={deleteItem}
    subtitle="Are you sure you want to delete this user?"
    />

    </>
  );
});

