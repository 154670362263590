import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import Cookies from 'universal-cookie';
import EditIcon from '@mui/icons-material/Edit';
import AlertDialog from 'elements/AlertDialog';
import { useSnackbar, withSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';

const cookies = new Cookies();

export default withSnackbar(
  function PlansList(props: any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any>([]);
  const [total_pages, setTotalPages] = useState(0);
  const currentPage= 1;
  const [page, setPage] = useState(currentPage);


  /*=========================================================
  LOAD LIST            
  =========================================================*/
  useEffect(() => {
    loadList();
  }, [page])
  /*=========================================================*/

  const loadList = () => {

    setLoading(true);
  
    axios.get(ProjectConfig.api_url,
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
      },
      params: {
        request: 'plans_list',
        params: {
          page: page,
        }
      }
    })
      .then((response) => {
        console.log(response.data);
        setList(response.data.list)
        setTotalPages(response.data.total_pages)
        setLoading(false)
          
      })
      .catch((err) => {
        console.log(err.data);
      })
      localStorage.setItem('page', page.toString())
  }



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (plan_id:number) => {
    setActiveItem(plan_id);
    setAlertOpen(true);
    
  }
  /*=========================================================*/

// call global snackbar

const deleteItem = () => {

  console.log('delete item', activeItem);
  axios.post(ProjectConfig.api_url, null, 
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
      },
      params: {
        request: 'delete_plan',
        plan_id: activeItem
      }
    })

    .then((response) => {
      console.log(response.data);

      if(response.data.status == 'success'){
        enqueueSnackbar('Plan deleted successfully', { variant: 'success' });
      }else{
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }


      loadList();
    })
    .catch((err) => {
      console.log(err.data);
    })
}
  
const [alertOpen, setAlertOpen] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);
  
  return (
    <>
    <div id='PlansList' className='ModuleContainer'>
      
      <div className='buttons_top'>

      </div>
        <div id="task_list_container" className="flex_container">

          <div className='boxContainerHeader'>
            <div className='boxContainerTitle'>
              <h2 className='h2_title'>Plans</h2>
              <span className='h2_subtitle'>List</span>
            </div>
            <div className='boxContainerActions'>
              <Button component={Link} to="/plans/add" variant="contained" startIcon={<AddIcon />}>
                Add Plan
              </Button>
            </div>
          </div>
          
            <Box id='user_list'>
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Plan Name</TableCell>
                  <TableCell>Days</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {list.map((item: any, i: any) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >

                      <TableCell>{item.plan_name}</TableCell>
                      <TableCell>{item.plan_days}</TableCell>
                      <TableCell>${item.plan_price}</TableCell>

                      <TableCell align='right' className='td_buttons'>
                        <Button
                          component={Link}
                          to={`/plans/edit/${item.plan_id}`}
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{marginRight: "10px"}}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>


                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(item.plan_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          {total_pages > 1 &&(
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
          )}
          </Box>
 
        </div>
    </div>

    <AlertDialog 
    open={alertOpen} 
    setOpen={setAlertOpen} 
    send={deleteItem}
    subtitle="Are you sure you want to delete this user?"
    />

    </>
  );
});

