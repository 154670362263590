import {
  Autocomplete,
  Button,
  Chip,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  styled,
  Switch,
  SwitchProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
  Pagination,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from "icons/ic_dots.svg";
import { ProjectConfig } from "Global";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import EventIcon from "@mui/icons-material/Event";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Cookies from "universal-cookie";

import TimerIcon from "@mui/icons-material/Timer";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import AccountMenu from "components/AmazonAccounts/AccountMenu";
import InvoicesList from "components/Invoices/InvoicesList";

const cookies = new Cookies();

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

//TODAY DATE FORMATED MM/DD/YYYY
const today = new Date("2014-08-18T21:11:54");

export default function ResellerEditor(props: any) {
  const [accountIdToStop, setAccountIdToStop] = useState(0);
  const [activeItem, setActiveItem] = useState<any>({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [dialogForcedStop, setDialogForcedStop] = useState(false);

  const { setLoading } = props;



  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let { user_id } = useParams();
  user_id = props.account_user_id ? props.account_user_id : user_id;
  /*=======================================
  =======================================*/

  const navigate = useNavigate();
  const defaultValues: any = {
    user_id: user_id ? user_id : "null",
    project_id: "",
    name: "",
    last_name: "",
    email: "",
    password: "",
    role_id: 3,
    is_active: true,
    phone: "",
    reseller_fee: 0,
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [roles_list, setRolesList] = useState<any[]>([]);
  const [files, setFiles] = useState<any>([]);

  /*=================================================
    GET ROLE LIST
  =================================================*/
  useEffect(() => {
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "role_list",
        },
      })
      .then((response) => {
        setRolesList(response.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);
  /*=================================================
  =================================================*/

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleContactPersonChange = (e: {
    target: { name: any; value: any };
  }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      contact_person: {
        ...formValues.contact_person,
        [name]: value,
      },
    });
  };

  const handleForeignerChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      foreigner_info: {
        ...formValues.foreigner_info,
        [name]: value,
      },
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("files", files);

    axios
      .post(ProjectConfig.api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          request: "add_edit_reseller",
          formdata: formValues,
        },
      })
      .then((response) => {
        console.log("res", props.account_user_id);

        if (props.account_user_id) {
          setLoading(false);
        } else {
          navigate("/resellers");
        }
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const [account_list, setAccountList] = useState<any>([]);
  const [total_pages, setTotalPages] = useState(0);


  const savedFilters = localStorage.getItem("filters") || "{}";
  const [page, setPage] = useState(1);

  /*=========================================================
  FILTERS
  =========================================================*/
  const filters = [
    { type: "text", label: "Email", name: "email", value: "" },
    { type: "text", label: "Name", name: "account_name", value: "" },
    {
      type: "select",
      label: "Status",
      name: "status",
      value: "",
      options: [
        { label: "All", value: "all" },
        { label: "Active", value: "active" },
        { label: "Expired", value: "expired" },
        { label: "Running", value: "running" },
      ],
    },
  ];
  const [filtersValues, setFiltersValues] = useState<any>(
    JSON.parse(savedFilters)
  );

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
    //save in localstorage
    localStorage.setItem("filters", JSON.stringify(tempFilters));
  };
  /*=========================================================*/

  /*=============================================
  ACCOUNT LIST
  =============================================*/

  useEffect(() => {
    loadList();
    setLoading(false);
  }, [page]);
  
  const loadList = () => {
    if(user_id){
      setLoading(true);
      axios
        .get(ProjectConfig.api_url, {
          headers: {
            LTKN: cookies.get("ltkn"),
          },
          params: {
            request: "af_accounts_list",
            params: {
              page: page,
              reseller_id: user_id,
              admin_list: true,
            },
          },
        })
        .then((response) => {
          console.log("loadlist", response.data);
          setAccountList(response.data.account_list);
          setTotalPages(response.data.total_pages);
          setLoading(false);
          //add gettings blocks : false to each account
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  };

  /**/

  useEffect(() => {
    if (user_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "reseller_details",
            user_id: user_id,
          },
        })
        .then((response) => {
          console.log(response.data);
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);



 
  return (
    <div id="ResellerEditor" className="ModuleContainer">
      <div className="userForm_right">
        <Box
          id="project_editor"
          className="box_editor forced_box_editor"
          title="Reseller"
          subtitle="Edit"
        >
          <div className="form">
            <div className="input_group">
              <h2>Personal Info</h2>
              <div className="input_row">
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  type="text"
                  required
                  fullWidth
                  value={formValues.name}
                  onChange={handleInputChange}
                />

                <TextField
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  type="text"
                  required
                  fullWidth
                  value={formValues.last_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="input_group">
              <h2>Login Info</h2>
              <div className="input_row">
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="text"
                  required
                  fullWidth
                  value={formValues.email}
                  onChange={handleInputChange}
                />

                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  required
                  fullWidth
                  value={formValues.password}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <TextField
              id="phone"
              name="phone"
              label="Phone"
              type="text"
              required
              fullWidth
              value={formValues.phone}
              onChange={handleInputChange}
            />

            <div className="input_group">
              <TextField
                id="reseller_fee"
                name="reseller_fee"
                label="Reseller Fee"
                type="number"
                required
                fullWidth
                value={formValues.reseller_fee}
                onChange={handleInputChange}
              />
            </div>


            

            {/* {props.account_user_id ? null :
              <FormControlLabel
                checked={formValues.is_active}
                
                control={
                  <IOSSwitch 
                    sx={{ m: 1 }}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        ["is_active"]: e.target.checked
                      });
                    }
                    }
                  />
                }
                label="Is Active"
              />
              } */}
          </div>
          <div className="buttons">
            <Button
              onClick={() => navigate(-1)}
              variant="outlined"
              className="button_2"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className="button_1"
            >
              Submit
            </Button>
          </div>
        </Box>
      </div>
      {user_id && (
        <>
        <Box title="Reseller Accounts" subtitle="Edit">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right">Expiration time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {account_list.map((account: any, i: any) => {
                //convert microseconds account.running_config.sleep_time to seconds
                const speed_time = account.running_config.sleep_time / 1000000;
                return (
                  <TableRow key={i} hover role="checkbox">
                    <TableCell>
                      <span className="gray_text">{account.account_id}</span>
                    </TableCell>
                    <TableCell>
                        <span className={"account_status_span status_"+account.status}>{
                        account.status === "running" && "Running"
                        || account.status === "paused" && "Paused"
                        || account.status === "stopped" && "Stopped"
                        }
                        </span>


                    </TableCell>

                    <TableCell>
                      <div className="flexTd">
                        <span className="flexTd_bold">{account.email}</span>
                      </div>
                    </TableCell>

                    <TableCell align="right" style={{ whiteSpace: "nowrap" }}>

                      <div className="flex_row">
                        {account.time_left > 0 ? (
                          <Chip
                            label={`${account.time_left_label}`}
                            color="primary"
                            size="small"
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            label="Expired"
                            color="error"
                            size="small"
                            variant="outlined"
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}

            {total_pages > 1 && (
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
          )}
            </TableBody>
          </Table>
        </Box>

        <InvoicesList reseller_id={user_id} is_admin={true} setLoading={setLoading} />
        </>
        

      )}
    </div>
  );
}
