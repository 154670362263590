
import { TextField } from '@mui/material';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import Box from 'elements/Box';
import { useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom';


import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';
import DateRange from 'elements/DateRange';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function AdminDashboard (props: any) {
  //const val = newValue? (newValue).format('YYYY-MM-DD') : '';
  //defaultdATE IN FORMAT YYYY-MM-DD
  const defaultDate = moment().format('YYYY-MM-DD');
  const navigate = useNavigate();


  //first and last date of the month
  const firstDate = moment().startOf('month').format('YYYY-MM-DD');
  const lastDate = moment().endOf('month').format('YYYY-MM-DD');
  
  const [date, setDate] = useState({
    startDate: firstDate,
    endDate: lastDate,
  });

  useEffect(() => {
    props.setLoading(false);
  }, []);
  
  const [indicators, setIndicators] = useState<any>(
    {
      total_accounts: 0,
      active_accounts: 0,
      total_running_accounts: 0,
      total_incomes: 0,
      captured_blocks: 0,
    }
  );

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    getDashboardData()
  }, [date])
/*=========================================================*/

  const openAccounts = (filters:any) => {
    console.log("filters",filters);
    localStorage.setItem('filters', JSON.stringify(filters))
    // using Link dom router
    navigate('/admin-amazon-accounts');
    
  };



  const getDashboardData = () => {

    axios.get(ProjectConfig.flexapi+"/offers/dashboardData",
    {
      params: {
        date: date,
      }
    })
      .then((response) => {
        console.log("res",response.data);
        setIndicators(response.data);
        
      })
      .catch((err) => {
        console.log(err);
      })
  }

  
  return (
    <div id='Home' className='module_container'>
      <div className="module_topBar">

        <DateRange
            className='filters_datePicker'
            label='Date range'
            variant="outlined"
            fullWidth
            value={{
              startDate: date.startDate,
              endDate:  date.endDate,
            }}
            format="MMMM DD, yyyy"
            onChange={(e: any) => {
              if (e.startDate && e.endDate) {
                const startDate = moment(e.startDate).format('YYYY-MM-DD');
                const endDate = moment(e.endDate).format('YYYY-MM-DD');

                setDate({
                  startDate: startDate,
                  endDate: endDate,
                });
                
              }
            }}
            variantDatePicker="side-by-side"
          />

    
      </div>
      <div className='module_content'>
        <div id='iconsContainer'>
            <h2>Dashboard</h2>
            <div id='indicatorsContainer'>
              <Box id='indicator_total_accounts' className='indicatorItem'>
                <span className='indicatorNumber'>{indicators.total_accounts}</span>
                <span className='indicatorLabel'>Total Accounts</span>
              </Box>
              <Box id='indicator_total_running_accounts' className='indicatorItem'>
                <OpenInNewIcon className='indicatorIcon' onClick={() => openAccounts({
                  status: 'running'
                })}/>
                <span className='indicatorNumber'>{indicators.total_running_accounts}</span>
                <span className='indicatorLabel'>Running</span>
              </Box>
              <Box id='indicator_active_accounts' className='indicatorItem'>
                <OpenInNewIcon className='indicatorIcon' onClick={() => openAccounts({
                  status: 'active'
                })}/>
                <span className='indicatorNumber'>{indicators.active_accounts}</span>
                <span className='indicatorLabel'>Active</span>
              </Box>
              <Box id='indicator_total_accounts' className='indicatorItem'>
                <OpenInNewIcon className='indicatorIcon' onClick={() => openAccounts({
                  status: 'expired'
                })}/>
                <span className='indicatorNumber'>{indicators.expired_accounts}</span>
                <span className='indicatorLabel'>Expired</span>
              </Box>
              <Box id='indicator_total_incomes' className='indicatorItem'>
                <span className='indicatorNumber'>{indicators.total_incomes.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                <span className='indicatorLabel'>Total Incomes</span>
              </Box>
              <Box id='indicator_captured_blocks' className='indicatorItem'>
                <span className='indicatorNumber'>{indicators.captured_blocks}</span>
                <span className='indicatorLabel'>Captured Blocks</span>
              </Box>

            </div>
            
        </div>

      </div>
    
        
    </div>
  );
};


