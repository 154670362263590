
import { Button, TextField, Tooltip } from '@mui/material';
import { ProjectConfig } from 'Global';
import axios from 'axios';
import Box from 'elements/Box';
import { useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom';


import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';
import DateRange from 'elements/DateRange';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Cookies from 'universal-cookie';
import { useSnackbar } from 'notistack';
const cookies = new Cookies();

export default function ResellerDashboard (props: any) {

  const { setLoading, isMobile } = props;
  //const val = newValue? (newValue).format('YYYY-MM-DD') : '';
  //defaultdATE IN FORMAT YYYY-MM-DD
  const defaultDate = moment().format('YYYY-MM-DD');
  const navigate = useNavigate();


  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */


  //first and last date of the month
  const firstDate = moment().startOf('month').format('YYYY-MM-DD');
  const lastDate = moment().endOf('month').format('YYYY-MM-DD');
  
  const [date, setDate] = useState({
    startDate: firstDate,
    endDate: lastDate,
  });

  useEffect(() => {
    setLoading(false);
  }, []);
  
  const [indicators, setIndicators] = useState<any>(
    {
      reseller_incomes: 0,
      reseller_accounts: 0,
    }
  );

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    getDashboardData()
  }, [date])
/*=========================================================*/

  const openAccounts = (filters:any) => {
    console.log("filters",filters);
    localStorage.setItem('filters', JSON.stringify(filters))
    // using Link dom router
    navigate('/admin-amazon-accounts');
    
  };

  



  const getDashboardData = () => {

    axios.get(ProjectConfig.flexapi+"/resellers/Dashboard",
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
      },
      params: {
        date: date,
      }
    })
      .then((response) => {
        console.log("res",response.data);
        setIndicators(response.data);
        
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const copyToClipboard = (text:any) => {
    navigator.clipboard.writeText(text)
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
  }



  
  
  return (
    <div id='Home' className='module_container'>
      <div className="module_topBar">

        <DateRange
            className='filters_datePicker'
            label='Date range'
            variant="outlined"
            fullWidth
            value={{
              startDate: date.startDate,
              endDate:  date.endDate,
            }}
            format="MMMM DD, yyyy"
            onChange={(e: any) => {
              if (e.startDate && e.endDate) {
                const startDate = moment(e.startDate).format('YYYY-MM-DD');
                const endDate = moment(e.endDate).format('YYYY-MM-DD');

                setDate({
                  startDate: startDate,
                  endDate: endDate,
                });
                
              }
            }}
            variantDatePicker="side-by-side"
          />

    
      </div>
      <div className='module_content'>
        <div id='iconsContainer'>
            <h2>Reseller Dashboard</h2>
            <div id='indicatorsContainer'>
              
              <Box className='indicatorItem'>
                <span className='indicatorNumber'>{indicators.reseller_incomes.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                <span className='indicatorLabel'>Incomes</span>
              </Box>
              <Box className='indicatorItem'>
                <span className='indicatorNumber'>{indicators.reseller_accounts}</span>
                <span className='indicatorLabel'>Accounts</span>
              </Box>

            </div>

            <h2>Reseller link</h2>
            <p>Copy and share this link with your customers to create their accounts</p>
            <div className='resellerLinkContainer'>
              <div className='resellerLink' onClick={() => copyToClipboard(indicators.reseller_link)} style={{cursor: 'pointer'}}>
                <span className='resellerLinkText'>Click to Copy</span>
                <Tooltip title="Copy link to clipboard" arrow>
                  <ContentCopyIcon className='copyIcon'/>
                </Tooltip>
              </div>
            </div>

           
            
        </div>

      </div>
    
        
    </div>
  );
};


