import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import Cookies from 'universal-cookie';
import EditIcon from '@mui/icons-material/Edit';
import AlertDialog from 'elements/AlertDialog';
import { useSnackbar, withSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CachedIcon from '@mui/icons-material/Cached';
import SaveIcon from '@mui/icons-material/Save';

const cookies = new Cookies();



  

export default withSnackbar(
  function Settings(props: any) {

    
  const [plans_list, setPlansList] = useState<any>([]);

    const [alertOpen, setOpenAlert] = useState(false);    

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const [googleLinkStatus, setGoogleLinkStatus] = useState<any>(true);
  const [settings, setSettings] = useState<any>(null);

  const {setLoading} = props;

  const [planId, setPlanId] = useState(1);

  useEffect(() => {
    getSettings();

    getPlansList();
    checkGoogleLink();
    setLoading(false);
    
  }, []);



  useEffect(() => {
    if (settings) {
      setPlanId(settings.resellers.plan_id);
    }
  }, [settings]);

  const getSettings = () => {
    setLoading(true)
    axios.get(ProjectConfig.flexapi+"/settings/getSettings")
      .then((response) => {
        console.log("settings",response.data);
        setSettings(response.data.settings);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.data);
      })
}



  /*=========================================================
    GET PLANS LIST
    =========================================================*/
    const getPlansList = () => {
      setLoading(true)
      axios.get(ProjectConfig.api_url,
      {
          headers: {
            LTKN: cookies.get("ltkn"),
          },
          params: {
              request: 'plans_list',
              params: {
                  fullList: true
              }
          }
      })
          .then((response) => {
          console.log("plans",response.data);
          setPlansList(response.data.list)
          setLoading(false)
              
          })
          .catch((err) => {
          console.log(err.data);
          })
  }



  const checkGoogleLink = () => {

    axios.get(ProjectConfig.flexapi+"/google/checkGoogleLink")
      .then((response) => {
        console.log("res check",response.data);
        setGoogleLinkStatus(response.data.status);
      })
      .catch((err) => {
        console.log(err);
      })
  }


  const deleteLogs = () => {
    setLoading(true);
    axios.get(ProjectConfig.flexapi+"/logs/deleteLogs")
        .then((response) => {
          console.log("res check",response.data);
          enqueueSnackbar(response.data.message, { variant: response.data.status });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          setOpenAlert(false);
        })
  }


  const [popupData, setPopupData] = useState(null);


  const openPopup = async () => {

    //get domain 
    let domain = window.location.origin;
    domain = domain.replace('://', '/');

    // const redirect_url = keys.web.redirect_uris[0];
    const redirect_url = ProjectConfig.google.redirect_uris[0];


    let scope = "";
    scope += encodeURIComponent('https://www.googleapis.com/auth/cloud-platform ');
    scope += encodeURIComponent('https://www.googleapis.com/auth/compute ');
    scope += encodeURIComponent('https://www.googleapis.com/auth/compute.readonly');

    const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&scope=${scope}&redirect_uri=${redirect_url}&client_id=${ProjectConfig.google.client_id}&access_type=offline&prompt=consent&include_granted_scopes=true&state=state_parameter_passthrough_value`;
    
    const options = 'width=600,height=400,scrollbars=yes';
    const popup = window.open(url, 'Google Login', options);

    
    const pollPopup = setInterval(() => {
        if (!popup || popup.closed) {
            //GET LAST URL FROM POPUP
          clearInterval(pollPopup);
  
          console.log("popup closed");
          checkGoogleLink();
          
        }
      }, 500);

}

const updateServerIP = () => {
  setLoading(true);
  axios.get(ProjectConfig.flexapi+"/google/stopAndStartInstance")
      .then((response) => {
        console.log("res check",response.data);
        enqueueSnackbar(response.data.message, { variant: response.data.status });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      })
}

  const updateResellerConfiguration = () => {
    setLoading(true);
    axios.post(ProjectConfig.flexapi+"/resellers/updateConfiguration", {
      plan_id: planId
    })
        .then((response) => {
          console.log("res check",response.data);
          enqueueSnackbar(response.data.message, { variant: "success" });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        })
  }


  
  return (
    <>
    <div id='Settings' className='ModuleContainer'>
      
      <div className='buttons_top'>

      </div>
        <div className="flex_container">

          <div className='boxContainerHeader'>
            <div className='boxContainerTitle'>
              <h2 className='h2_title'>Settings</h2>
              <span className='h2_subtitle'>Manage your configuration</span>
            </div>
            <div className='boxContainerActions'>
             
            </div>
          </div>

            {/* <Box id='settings_box'>
              {ProjectConfig.google.isActive ? 
                
                !googleLinkStatus ?
                <Button 
                    variant="contained"   
                    startIcon={<InsertLinkIcon />} 
                    onClick={openPopup}
                  >
                      Link With Google
                  </Button>
                  :
                  <div>
                    <span>Google is linked </span>
                    <Button 
                      variant="contained"   
                      startIcon={<CachedIcon />} 
                      onClick={() => setAlertIPOpen(true)}
                    >
                        Update Server IP
                    </Button>
                  </div>
                : 
                <span>Google API is not configured</span>
              }
       
            </Box> */}
 
        </div>

        <Box id='settings_box' title="Resellers configuration">
          <FormControl variant="outlined" className="select" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Reseller Plan</InputLabel>
            <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={planId}
            fullWidth
            sx={{ width: "100%" }}
            onChange={(e) => {
                setPlanId(parseInt(e.target.value as string))
                    
            }}
            label="Reseller Plan"
            >
                {plans_list.map((plan: any, index: number) => {
                    return (
                        <MenuItem key={index} value={plan.plan_id}>{plan.plan_name} - {plan.plan_days} Days</MenuItem>
                    )
                })}
            </Select>
          </FormControl>

          <Button
            variant="contained"   
            startIcon={<SaveIcon />} 
            onClick={() => {
              updateResellerConfiguration();
            }}
          >
              Update configuration
          </Button>
        </Box>


        <Box id='settings_box' title="Server configuration">
          <span>Delete LOGS</span>
          <Button
            variant="contained"   
            color='error'
            startIcon={<DeleteOutlineOutlinedIcon />} 
            onClick={() => {
              setOpenAlert(true);
             
            }}
          >
              Delete Logs
          </Button>
        </Box>
    </div>

    <AlertDialog
        open={alertOpen}
        setOpen={setOpenAlert}
        send={deleteLogs}
        subtitle="Are you sure you want to delete the logs? This action is irreversible"
      />
    
    </>
  );
});

