import { Button, Chip, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ProjectConfig } from "Global";
import axios from "axios";
import AlertDialog from "elements/AlertDialog";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import PaymentDialog from 'elements/PaymentGateways/Stripe/StripeDialog';
import StripeTerms from "./StripeTerms";

const cookies = new Cookies();

export default function AddTime(props: any) {

    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [user_id, setUserId] = useState("");

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */


    const handleOpenPaymentDialog = () => {
        setOpenPaymentDialog(true);
    }
    
    const {
        setLoading, 
        open, 
        setOpen, 
        account_id, 
        loadOrders, 
        isUser = false,
        hasReseller
    } = props;
    
    const [planId, setPlanId] = useState(0);
    const [plans_list, setPlansList] = useState<any>([]);
    const [alertOpen, setAlertOpen] = useState(false);

    const [selectedPlan, setSelectedPlan] = useState<any>(null);


    useEffect(() => {
       getPlansList();
       setUserId(localStorage.getItem('user_id') || "");
    }, []);

    useEffect(() => {
        if (plans_list.length > 0 && planId)
            setSelectedPlan(plans_list.find((plan: any) => plan.plan_id == planId));
        else setSelectedPlan(null);
    }, [plans_list, planId]);


    /*=========================================================
    GET PLANS LIST
    =========================================================*/
    const getPlansList = () => {
        setLoading(true)
        axios.get(ProjectConfig.api_url,
        {
            headers:{
                'LTKN': cookies.get('ltkn'),
            },
            params: {
                request: 'plans_list',
                params: {
                    fullList: true,
                    is_user: isUser,
                }
            }
        })
            .then((response) => {
            console.log("plans",response.data);
            setPlansList(response.data.list)
            setLoading(false)
            if (response.data.list.length > 0){
                setPlanId(response.data.list[0].plan_id)
            }
                
            })
            .catch((err) => {
            console.log(err.data);
            })
    }
 
    /*=========================================================
    ADD TIME 
    =========================================================*/
    const addTime = () => {
        setLoading(true);

        const params: any = {
            account_id: account_id,
            plan_id: planId,
            user_id: user_id,
        };

        if (isUser) {
            params.is_user = isUser;
        }

        axios.post(ProjectConfig.api_url, null,
        {
            headers:{
                'LTKN': cookies.get('ltkn'),
              },
            params: {
                request: 'add_time_to_account',
                params,
            }
        })
        .then((response) => {
            console.log(response.data);
            
            enqueueSnackbar('Time added successfully', { variant: 'success' });
            setLoading(false)
            setOpen(false)
            loadOrders()
            
        })
        .catch((err) => {
            console.log(err);
        })
    }
    
    
  
  return (
    <>
    <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >  
        <DialogContent  className="forced_box_editor">
            <div className="dialog_header">
                <h2 className='dialog_h2'>Select a plan to add time to account </h2>
                
            </div>
                <div className="form">
                    
                    <FormControl variant="outlined" className="select" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">Plan</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={planId}
                            fullWidth
                            sx={{ width: "100%" }}
                            onChange={(e) => {
                                setPlanId(parseInt(e.target.value as string))
                            }}
                            label="Plan"
                        >
                            {plans_list.map((plan: any, index: number) => {
                                return (
                                    <MenuItem key={index} value={plan.plan_id}>{plan.plan_name} - {plan.plan_days} Days (${plan.plan_price})</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                
            

            <div className="buttons">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAlertOpen(true)}
                    //if plan selected is value 0
                    disabled={planId == 0}
                >
                    Add time
                </Button>
            </div>
        </DialogContent>
            
    </Dialog>

    
    <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={isUser ? handleOpenPaymentDialog : addTime}
        subtitle={<StripeTerms />}
        />


    {(selectedPlan !== null && isUser) &&
    
    <PaymentDialog
        open={openPaymentDialog}
        amount={selectedPlan.plan_price}
        metadata={{
            concept: 'user_plan_payment',
            plan_id: selectedPlan.plan_id,
            account_id: account_id,
            user_id: user_id,
            is_user: isUser,
            ltkn: cookies.get('ltkn'),
        }}
        handleOpen={(e: any) => {
          console.log(e)
          setOpenPaymentDialog(e);
        }}
        onResponse={(e: any) => {
          if (e === 'succeeded') {
            enqueueSnackbar('Payment successful', { variant: 'success' });
            setLoading(false);
            setOpen(false);
            loadOrders();
          } else {
            enqueueSnackbar('Payment failed', { variant: 'error' });
          }
          setOpenPaymentDialog(false);
        }}
    />
    }


    </>
  );
}

