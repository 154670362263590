import { Button, Chip, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ProjectConfig } from "Global";
import axios from "axios";
import AlertDialog from "elements/AlertDialog";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import AddTime from './AddTime';

  export default function Orders(props: any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */
    
  const {setLoading, open, setOpen, account_id, loadList, setAccountId, reseller_id, isUser = false, hasReseller = false} = props;
  const isReseller = reseller_id ? true : false;

  const [openAddTime, setOpenAddTime] = useState(false);
  const [list, setList] = useState<any>([]);

  const [total_pages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

    useEffect(() => {
        console.log("account_id",account_id);
        if (account_id != 0) {
         loadOrders();
        }
    }, [account_id]);
    

    const loadOrders = () => {
        setLoading(true)
        axios.get(ProjectConfig.api_url,
        {
            params: {
                request: 'get_orders_list',
                params: {
                    account_id: account_id,
                    page: page,
                    reseller_id: reseller_id
                }
            }
        })
            .then((response) => {
            console.log("orders",response.data);
            setList(response.data.list)
            setTotalPages(response.data.total_pages)
            setLoading(false)
            })
            .catch((err) => {
            console.log(err.data);
            })
    }

    const [idSelected, setIdSelected] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);



    

    const deleteOrder = () => {
        
        setLoading(true)
        axios.post(ProjectConfig.api_url, null,
        {
            params: {
                request: 'delete_order',
                order_id: idSelected
                
            }
        })
            .then((response) => {
            console.log(response.data);
            if (response.data.status == 'success') {
                enqueueSnackbar('Order deleted', {variant: 'success'});
                
            } else {
                enqueueSnackbar('Error deleting order', {variant: 'error'});
            }
            loadOrders();
            setLoading(false)
            })
            .catch((err) => {
            console.log(err.data);
            })

    }
  
  return (
    <>
    <Dialog
        open={open}
        onClose={() => {
            setOpen(false)
            setAccountId(0);
            loadList();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >  
        <DialogContent  className="forced_box_editor">
            <div className="dialog_header">
                <h2 className='dialog_h2'>ACCOUNT TIME </h2>
                
            </div>
            <div className="form">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Order ID
                            </TableCell>
                            <TableCell>
                                Date
                            </TableCell>
                            <TableCell>
                                Days
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((item:any) => (
                            <TableRow key={item.order_id}>
                                <TableCell>
                                    {item.order_id}
                                </TableCell>
                                <TableCell>
                                    {item.date}
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={item.days}
                                        color="primary"
                                    />
                                </TableCell>
                                <TableCell>
                                    {!isReseller && !isUser && (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                setIdSelected(item.order_id);
                                                setAlertOpen(true)
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
                

                
            </div>
            
            
            

            <div className="buttons">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setOpen(false);
                        setAccountId(0);
                        loadList();
                    }}
                >
                    
                    Close
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddTime(true)}
                >
                    Add time
                </Button>
                

            </div>
        </DialogContent>
            
    </Dialog>


    <AddTime
        open={openAddTime}
        setOpen={setOpenAddTime}
        account_id={account_id}
        setLoading={setLoading}
        loadList={loadList}
        loadOrders={loadOrders}
        user_id={reseller_id}
        isUser={isUser}
        hasReseller={hasReseller}
    />

    <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={deleteOrder}
        subtitle="Are you sure you want to delete this order?"
        
      />



    </>
  );
}

