import AccountEditor from 'components/Account/AccountEditor';
import AmazonAccountsEditor from 'components/AmazonAccounts/Editor';
import AmazonAccountsList from 'components/AmazonAccounts/List';
import AmazonBlocks from 'components/AmazonAccounts/Blocks';
import AmazonAccountsListBlocks from 'components/AmazonBlocks/List';
import Home from 'components/Home/Home';
import ScheduleView from 'components/Schedule/ScheduleView';
import {BrowserRouter as Router , Route, Routes} from 'react-router-dom'
import Cookies from 'universal-cookie';
import UserList from 'components/Users/UserList';
import UserEditor from 'components/Users/UserEditor';
import AdminList from 'components/AmazonAccounts/AdminList';
import PlansList from 'components/Plans/PlansList';
import PlansEditor from 'components/Plans/PlansEditor';
import AdminDashboard from 'components/Home/AdminDashboard';
import ResellerList from 'components/Resellers/ResellerList';
import Settings from 'components/Settings/Settings';
import ResellerSettings from 'components/Settings/ResellerSettings';
import GoogleReceiver from 'components/Settings/GoogleReceiver';
import ResellerEditor from 'components/Resellers/ResellerEditor';
import ResellerDashboard from 'components/Home/ResellerDashboard';
import InvoicesList from 'components/Invoices/InvoicesList';
import InvoiceViewer from 'components/Invoices/InvoiceViewer';
import OrdersList from 'components/Orders/OrdersList';
import PaymentList from 'components/Payments/PaymentList';
import StripeTerms from 'components/AmazonAccounts/StripeTerms'

const cookies = new Cookies();

export default function AppRoutes(props:any) {

  const role_id = cookies.get('role_id');
  let reseller_id = 0;
  if(role_id == 3){
    reseller_id = cookies.get('user_id');
  }

  const {setLoading, isMobile} = props;

  

  return (
      <Routes>
        {/* HOME */}
        {role_id == 1 && (
          <Route path='/' element={<AmazonAccountsList setLoading={setLoading} isMobile={isMobile}/>} />
          
        )}
        {role_id == 2 && (
          <>
            <Route path='/' element={<AdminDashboard setLoading={setLoading} isMobile={isMobile}/>} />
            <Route path="/settings" element={<Settings setLoading={setLoading} isMobile={isMobile}/>} />
            <Route path="/google-receiver" element={<GoogleReceiver setLoading={setLoading} isMobile={isMobile}/>} /> 

            {/* PLANS */}
            <Route path="/plans" element={<PlansList setLoading={setLoading} isMobile={isMobile}/>} />
            <Route path="/plans/edit/:plan_id" element={<PlansEditor setLoading={setLoading} isMobile={isMobile}/>} />
            <Route path="/plans/add" element={<PlansEditor setLoading={setLoading} isMobile={isMobile}/>} />

            {/* RESELLERS */}
            <Route path="/resellers" element={<ResellerList setLoading={setLoading} isMobile={isMobile}/>} />
            <Route path="/resellers/add" element={<ResellerEditor setLoading={setLoading} isMobile={isMobile}/>} />
            <Route path="/resellers/edit/:user_id" element={<ResellerEditor setLoading={setLoading} isMobile={isMobile}/>} />

            {/* ORDERS */}
            <Route path="/orders" element={<OrdersList setLoading={setLoading} isMobile={isMobile} reseller_id={reseller_id}/>} />
          </>
        )}

        {role_id == 3 && (
          <>
            <Route path='/' element={<ResellerDashboard setLoading={setLoading} isMobile={isMobile}/>} />
            <Route path="/settings" element={<ResellerSettings setLoading={setLoading} isMobile={isMobile}/>} />
          </>
        )}

        {/* AMAZON ACCOUNT */}
        <Route path="/amazon-accounts" element={<AmazonAccountsList setLoading={setLoading} isMobile={isMobile}/>} />
        <Route path="/amazon-accounts/add" element={<AmazonAccountsEditor setLoading={setLoading} isMobile={isMobile} />} />
        <Route path="/amazon-accounts/edit/:account_id" element={<AmazonAccountsEditor setLoading={setLoading} isMobile={isMobile} />} />

        {/* AMAZON BLOCKS */}
        <Route path="/get-blocks/:account_id" element={<AmazonBlocks setLoading={setLoading} isMobile={isMobile}/>} />

        {/* MY ACCOUNT */}
        <Route path="/account" element={<AccountEditor setLoading={setLoading} isMobile={isMobile}/>} />

        {/* SCHEDULE */}
        <Route path="/schedule" element={<ScheduleView setLoading={setLoading} isMobile={isMobile}/>} />

        {/* USERS */}
        <Route path="/users" element={<UserList setLoading={setLoading} isMobile={isMobile}/>} />
        <Route path="/users/edit/:user_id" element={<UserEditor setLoading={setLoading} isMobile={isMobile}/>} />
        <Route path="/admin-amazon-accounts" element={<AdminList setLoading={setLoading} isMobile={isMobile}/>} />

        <Route path="/reseller-amazon-accounts" element={<AdminList reseller_id={reseller_id}
        setLoading={setLoading} isMobile={isMobile}/>} />

        

        {/* AMAZON BLOCKS */}
        <Route path="/amazon-blocks" element={<AmazonAccountsListBlocks setLoading={setLoading} isMobile={isMobile}/>} />

       


        {/* SETTINGS */}
        
  

        {/* INVOICES */}
        <Route path="/invoices" element={<InvoicesList setLoading={setLoading} isMobile={isMobile} reseller_id={reseller_id}/>} />

        

        {/* invoice-details */}
        <Route path="/invoices/details/:invoice_id" element={<InvoiceViewer setLoading={setLoading} isMobile={isMobile} />} /> 

        <Route path="/payments" element={<PaymentList setLoading={setLoading} isMobile={isMobile}/>} />
      </Routes>
  );
}
