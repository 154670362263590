import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AlertDialog from 'elements/AlertDialog';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import Filters from 'elements/Filters/Filters';
import TuneIcon from '@mui/icons-material/Tune';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import Cookies from 'universal-cookie';
import AddTime from './AddTime';

const cookies = new Cookies();
export default function PaymentList(props: any) {

  const {
    setLoading,
  } = props;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

    /*=========================================================
  FILTERS
  =========================================================*/
  const filters = [
    {type: "text", label: "Email", name: "parent.email", value: ""},
    {type: "date_range", label: "Name", name: "date_range", value: ""},
  ]
  
  const [filtersValues, setFiltersValues] = useState<any>([]);

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
  }
  /*=========================================================*/

  useEffect(() => {
    setLoading(false);
  }, [])

  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const order = queryParams.get('order');
  const [refreshList, setRefreshList] = useState(0);

  const [open, setOpen] = useState(false);

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    setLoading(true);
    getOrdersList();
  }, [page, refreshList, filtersValues])

  console.log("filtersValues",filtersValues);

  const getOrdersList = () => {
    axios.get(ProjectConfig.flexapi + '/orders/listOrdersByUser',
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
      },
      params: {
        request: 'orders_list',
        page: page,
        filters: filtersValues,
      }
    })
      .then((response) => {
        console.log("ul ",response.data); 
        setList(response.data.list)
        setTotalPages(response.data.total_pages)
        setLoading(false)
         
      })
      .catch((err) => {
        console.log(err.data);
      })
      localStorage.setItem('page', page.toString())
  }
  /*=========================================================*/


  
  return (
    <>
      <div id='UsersList' className='ModuleContainer'>
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Payments</h2>
              <span className="h2_subtitle">List</span>
            </div>
            
            <div className="boxContainerActions">
              <Button 
                variant="contained" 
                color="primary" 
                size="small"
                onClick={() => {
                  setOpen(true)
                }}
              >
                <AddIcon />
                PAY NOW
              </Button>
            </div>
            
          </div>

          <div id='UsersListIn'>
            
              <Box id='OrdersList' className='box100'>
              <TableContainer> 
              <Table stickyHeader aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell>Account / Order ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Days</TableCell>
                    <TableCell align='right'>Order Price</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((list_item,i) => {
                    
                      return (
                      <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                      >
                        <TableCell>  
                        <span className='td_column_title'>
                          {list_item.email}
                        </span>  
                        <span className='td_column_subtitle'>
                          {list_item.order_id}
                        </span>
                        
                          
                        </TableCell>
                        <TableCell>
                          <span className='td_column_title'>
                            {moment(list_item.date).format('MMM DD / YYYY')}
                          </span>
                          <span className='td_column_subtitle'>
                            {moment(list_item.date).format('hh:mm A')}
                          </span>
                        </TableCell>
                        <TableCell>
                          
                          {list_item.days}
                        </TableCell>
                        <TableCell align='right'>
                          <span className='td_column_title'>
                            {list_item.plan_price === 0? 'Free' : ''}
                            {list_item.plan_price > 0? '$ ' + list_item.plan_price : ''}
                          </span>
                        </TableCell>                        
                      </TableRow>
                    )})}
                </TableBody>
              </Table>
            </TableContainer>
            
            {total_pages > 1 && (
              <Pagination count={total_pages} page={page}  onChange={(event, value) => {
                setPage(value)
              }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
            )}
            </Box>

            <AddTime open={open} setOpen={setOpen} loadOrders={getOrdersList} setLoading={setLoading} />
  
          </div>
        </div>
      </div>

      
    </>
  );
};
