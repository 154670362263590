import React from 'react';
import {
    PaymentElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';

export default function PaymentForm({
    onCanceled = () => {},
    onResult = (result: any) => {
        <PaymentResult status={result} />
    },
}: any) {

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
    
        if (!stripe || !elements) {
            return;
        }

        onResult(<PaymentWaitingResult />);
    
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
            },
            redirect: 'if_required',
                // return_url: `${window.location.protocol}//${window.location.host}/stripe/payment-result`,
        });
    
        if (result.error) {
            onResult(result.error.message);
        } else {
            onResult(result.paymentIntent.status);
        }
    };
    
    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
                variant='contained'
                color='primary'
                fullWidth
                sx={{
                    mt: 3,
                    backgroundColor: '#000',
                }}
                onClick={onCanceled}
            >
                Cancel
            </Button>

            <Button
                variant='contained'
                color='primary'
                type="submit"
                fullWidth
                disabled={!stripe}
                sx={{
                    mt: 1,
                    backgroundColor: '#000',
                }}
            >
                Submit
            </Button>
        </form>
    );

};

export function PaymentResult({
    status = null,
}: any) {

    return (
        <div>
            Payment Result
            <br />
            <div>
                <span> Redirect Status </span>
                <span> { status } </span>
            </div>
        </div>
    );

}

export function PaymentWaitingResult() {

    return (
        <div>
            Waiting Payment Result
        </div>
    );
    
}