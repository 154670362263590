import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ic_menu from 'icons/ic_menu.svg';
import Cookies from 'universal-cookie';

import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import RedeemIcon from '@mui/icons-material/Redeem';
import SpeedIcon from '@mui/icons-material/Speed';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HistoryIcon from '@mui/icons-material/History';

const cookies = new Cookies();






export default function MainMenu(props: any) {
  
  const theme = useTheme();
  const drawerWidth = 240;
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));


  const {openDrawer, setOpenDrawer, isMobile} = props;


  const role_id = cookies.get('role_id');


  //check active menu 
  const checkActiveMenu = (href: string) => {
    if(window.location.pathname == href){
      return true;
    }else{
      return false;
    }
  }


  
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          border: 0,
          boxShadow: "0 0 30px #00000012",
        },
      }}
      variant={isMobile? "temporary" : "permanent"}
      anchor="left"
      open={isMobile? openDrawer : true}
      onClose={() => {
        isMobile && setOpenDrawer(false)
      }}
    >
      <DrawerHeader>
        <div id="menu-header">


          <div id="logo">
            <Link to="/">
              <img src="/images/logo.png"></img>
            </Link>
          </div>
        </div>
      </DrawerHeader>
      <List>
        {role_id == 1 && (
          <>
            <ListItem disablePadding onClick={() => {isMobile && setOpenDrawer(false)}}>
              <ListItemButton component={Link} to="/" selected={checkActiveMenu("/")}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding onClick={() => {isMobile && setOpenDrawer(false)}}>
              <ListItemButton component={Link} to="/schedule" selected={checkActiveMenu("/schedule")}>
                <ListItemIcon>
                  <CalendarMonthIcon />
                </ListItemIcon>
                <ListItemText primary="Schedule" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding onClick={() => {isMobile && setOpenDrawer(false)}}>
              <ListItemButton component={Link} to="/payments" selected={checkActiveMenu("/payments")}>
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Payments" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding onClick={() => {isMobile && setOpenDrawer(false)}}>
              <ListItemButton component={Link} to="/account" selected={checkActiveMenu("/account")}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configuration" />
              </ListItemButton>
            </ListItem>

          </>
        )}

        {role_id == 2 && (
          <>
            {/* dashboard */}
            <ListItem disablePadding onClick={() => {isMobile && setOpenDrawer(false)}}>
              <ListItemButton component={Link} to="/" selected={checkActiveMenu("/")}>
                <ListItemIcon>
                  <SpeedIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/users">
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/admin-amazon-accounts">
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary="Accounts" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/plans">
                <ListItemIcon>
                  <RedeemIcon />
                </ListItemIcon>
                <ListItemText primary="Plans" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/orders">
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/resellers">
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText primary="Resellers" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/invoices">
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Invoices" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItem>
            


          </>
        )}

      {role_id == 3 && (
          <>
            <ListItem disablePadding onClick={() => {isMobile && setOpenDrawer(false)}}>
              <ListItemButton component={Link} to="/" selected={checkActiveMenu("/")}>
                <ListItemIcon>
                  <SpeedIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/reseller-amazon-accounts">
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary="Accounts" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/invoices">
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Invoices" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItem>

          </>
        )}
        
      </List>
    </Drawer>
  );
}
