import React, { ReactNode, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogTitle, TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';

/* INTERFACES */
interface DateRangeValueProp {
    startDate: Date;
    endDate: Date;
}

/* Types */
type DateRangeVariant = 'two-steps' | 'side-by-side';

/* Constants */
const defaultRangeValue: DateRangeValueProp = {
    startDate: new Date(),
    endDate: new Date(),
};

/**
 * DateRange
 *  - This component renders a two-step date range picker
 * @param props
 * @returns 
 */
export default function DateRange ( props: any ) {

    const {
        label = '',
        placeholder = '',
        value = defaultRangeValue,
        format = 'MM/dd/yyyy',
        onChange = () => {},
        disabled = false,
        required = false,
        error = false,
        helperText = '',
        fullWidth = false,
        inputProps = {},
        InputProps = {
            endAdornment: (
                <EventIcon />
            ),
        },
        InputLabelProps = {},
        variant = 'standard',
        size = 'medium',
        color = 'primary',
        className = '',
        style = {},
        sx = {},
        variantDatePicker = 'two-steps' as DateRangeVariant,
        ...otherProps
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [isOpenStartDatePicker, setIsOpenStartDatePicker] = useState(false);
    const [isOpenEndDatePicker, setIsOpenEndDatePicker] = useState(false);
    const [isPendingToConfirm, setIsPendingToConfirm] = useState(false);

    const [startDate, setStartDate] = useState<Date>(defaultRangeValue.startDate);
    const [endDate, setEndDate] = useState<Date>(defaultRangeValue.endDate);

    const [dateRangeTextValue, setDateRangeTextValue] = useState<string>('');

    /* VALIDATE */
    useEffect(() => {
        let start;
        let end;

        
        if (checkIsDate(startDate)) start = moment(startDate).format(format);
        else {
            start = moment(defaultRangeValue.startDate).format(format);
            enqueueSnackbar('Invalid start date', { variant: 'error' });
        }

        if (checkIsDate(endDate)) end = moment(endDate).format(format);
        else {
            end = moment(defaultRangeValue.endDate).format(format);
            enqueueSnackbar('Invalid end date', { variant: 'error' });
        }

        if (!checkIsDateRange({ startDate, endDate })) {
            if (startDate > endDate) {
                end = moment(startDate).format(format);
                setEndDate(new Date(moment(startDate).format(format)));
                enqueueSnackbar('End date must be greater than start date', { variant: 'error' });
            }
        }

        setDateRangeTextValue(`${start} - ${end}`);
    }, [startDate, endDate]);

    useEffect(() => {
        if (checkIsDateRange(value)) {
            if (typeof value.startDate === 'string') setStartDate(new Date(value.startDate.replaceAll('-','/')));
            else setStartDate(value.startDate);

            if (typeof value.endDate === 'string') setEndDate(new Date(value.endDate.replaceAll('-','/')));
            else setEndDate(value.endDate);
        } else {
            setStartDate(defaultRangeValue.startDate);
            setEndDate(defaultRangeValue.endDate);
        }
    }, [value]);

    useEffect(() => {
        console.log('isPendingToConfirm', isPendingToConfirm)
        if (isPendingToConfirm) {
            handleConfirm();
            setIsPendingToConfirm(false);
        }
    }, [isPendingToConfirm]);

    
    const checkIsDate = (date: any) => {
        return date && new Date(date).toString() !== 'Invalid Date';
    };

    const checkIsDateRange = (dateRange: DateRangeValueProp) => {
        let isValid = false;

        if (!dateRange)
            return isValid;

        if (!dateRange.startDate)
            return isValid;

        if (!dateRange.endDate)
            return isValid;

        if (!checkIsDate(dateRange.startDate))
            return isValid;
        
        if (!checkIsDate(dateRange.endDate))
            return isValid;

        if (dateRange.startDate > dateRange.endDate)
            return isValid;

        isValid = true;
        return isValid;
    };

    /* HANDLE CONFIRM AND CANCEL */
    const handleCancel = () => {
        if (typeof value.startDate === 'string') setStartDate(new Date(value.startDate.replaceAll('-','/')));
        else setStartDate(value.startDate);

        if (typeof value.endDate === 'string') setEndDate(new Date(value.endDate.replaceAll('-','/')));
        else setEndDate(value.endDate);
    };

    const handleConfirm = () => {
        const dataRange = {
            startDate,
            endDate,
        };

        if (checkIsDateRange(dataRange) && startDate && endDate) {
            onChange({
                startDate: new Date(startDate),
                endDate: new Date(endDate),
            });
        } else {
            handleCancel();
        }
    };

    /* HANDLE CHANGE */

    const handleAcceptStartDate = (date: any, is_two_steps_variant: boolean) => {
        const dateObject = new Date(date.format(format));

        if (checkIsDate(dateObject)) {
            setStartDate(dateObject);
            
            if (is_two_steps_variant) {
                if (endDate && dateObject > endDate) setEndDate(dateObject);
                setIsOpenStartDatePicker(false);
                setIsOpenEndDatePicker(true);
            }
        }
    };

    const handleAcceptEndDate = (date: any, is_two_steps_variant: boolean) => {
        const dateObject = new Date(date.format(format));

        if (checkIsDate(dateObject)) {
            setEndDate(dateObject);

            if (is_two_steps_variant) {
                setIsOpenEndDatePicker(false);
                setIsPendingToConfirm(true);
            }
        }
    };

    /* RENDER */

    function renderStartDatePicker(is_two_steps_variant: boolean): ReactNode {
        return (
            <StaticDatePicker
                value={startDate}
                onChange={(date: any) => handleAcceptStartDate(date, is_two_steps_variant)}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        autoFocus
                        margin="normal"
                        variant="standard"
                        helperText="Choose start date"
                    />
                )}
                showToolbar={false}
                componentsProps={{
                    actionBar: {
                        actions: []
                    }
                }}
            />
        );
    };

    const renderEndDatePicker = (is_two_steps_variant: boolean) => {
        return (
            <StaticDatePicker
                value={endDate}
                onChange={(date: any) => handleAcceptEndDate(date, is_two_steps_variant)}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        autoFocus
                        margin="normal"
                        variant="standard"
                        helperText="Choose end date"
                    />
                )}
                showToolbar={false}
                componentsProps={{
                    actionBar: {
                        actions: []
                    }
                }}

                {...(
                    (checkIsDate(startDate)) ?
                    { minDate: moment(startDate) } :
                    {}
                )}
            />
        );
    };


    return (
        <>
            {/* Textfield: show the date-range value and onClick dialog the staticDatePicker for choose start date limit of dateRange */}
            <TextField
                id="date"
                className={className}
                label={label}
                placeholder={placeholder}
                value={dateRangeTextValue}
                fullWidth={fullWidth}
                variant={variant}
                size={size}
                color={color}
                style={style}
                sx={sx}
                disabled={disabled}
                required={required}
                error={error}
                helperText={helperText}
                inputProps={inputProps}
                InputProps={InputProps}
                InputLabelProps={InputLabelProps}
                onClick={() => {
                    setIsOpenStartDatePicker(true);
                }}
            />

            <Dialog
                open={isOpenStartDatePicker}
                onClose={(_, reason) => {
                    setIsOpenStartDatePicker(false);

                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        handleCancel();
                    }
                }}
                {...(variantDatePicker === 'side-by-side' ? { maxWidth: 'md' } : {})}
            >
                <DialogTitle>
                    {variantDatePicker === 'two-steps' && (
                        <>Choose start date</>
                    )}

                    {variantDatePicker === 'side-by-side' && (
                        <>Choose start and end date</>
                    )}
                </DialogTitle>

                {variantDatePicker === 'two-steps' && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        {renderStartDatePicker(true)}
                    </LocalizationProvider>
                )}

                {variantDatePicker === 'side-by-side' && (
                    <div className='input_row'>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            {renderStartDatePicker(false)}
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            {renderEndDatePicker(false)}
                        </LocalizationProvider>
                    </div>
                )}

                {variantDatePicker === 'two-steps' && (
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setIsOpenStartDatePicker(false);
                                handleCancel();
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            disabled={!checkIsDate(startDate)}
                            onClick={() => {
                                setIsOpenStartDatePicker(false);
                                setIsOpenEndDatePicker(true);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                )}

                {variantDatePicker === 'side-by-side' && (
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setIsOpenStartDatePicker(false);
                                handleCancel();
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            disabled={!checkIsDateRange({ startDate, endDate })}
                            onClick={() => {
                                setIsOpenStartDatePicker(false);
                                handleConfirm();
                            }}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
            
            <Dialog
                open={isOpenEndDatePicker}
                onClose={(_, reason) => {
                    setIsOpenEndDatePicker(false);

                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        handleCancel();
                    }
                }}
            >
                <DialogTitle> Choose end date </DialogTitle>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {renderEndDatePicker(true)}
                </LocalizationProvider>

                {variantDatePicker === 'two-steps' && (
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setIsOpenEndDatePicker(false);
                                handleCancel();
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            disabled={!checkIsDateRange({ startDate, endDate })}
                            onClick={() => {
                                setIsOpenEndDatePicker(false);
                                setIsPendingToConfirm(true);
                            }}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </>
    );

};