import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, Chip, CircularProgress, Dialog, DialogContent, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import Cookies from 'universal-cookie';
import EditIcon from '@mui/icons-material/Edit';
import AlertDialog from 'elements/AlertDialog';
import { useSnackbar, withSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Payment } from '@mui/icons-material';
import PaymentDialog from 'elements/PaymentGateways/Stripe/StripeDialog';
import InvoiceViewer from './InvoiceViewer';

const cookies = new Cookies();

export default withSnackbar(
  function InvoiceList(props: any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const {setLoading, reseller_id, is_admin = false, isMobile} = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const navigate = useNavigate();

  const [invoices_list, setInvoicesList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage= 1;

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  console.log('reseller_id', reseller_id);

  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    loadList();
  }, [page, refreshList])
  /*=========================================================*/

  const loadList = () => {
    setLoading(true);

    const params: any = {
      page: page,
    };

    if (role_id == 3 || is_admin) params.reseller_id = reseller_id;
    console.log(reseller_id, params);
  
    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'invoices_list',
        params,
      }
    })
      .then((response) => {
        console.log(response.data);
        setInvoicesList([...response.data.list]);
        setTotalPages(response.data.total_pages);
        setLoading(false);
          
      })
      .catch((err) => {
        console.log(err.data);
      })
      localStorage.setItem('page', page.toString())
  }

  
const [alertOpen, setAlertOpen] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);

  /*=========================================================
  PAYMENT INVOICE WITH STRIPE
  =========================================================*/

  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [invoiceSelectedToPay, setInvoiceSelectedToPay] = useState<any>(null);

  useEffect(() => {
    if (!openPaymentDialog) setInvoiceSelectedToPay(null);
  }, [openPaymentDialog]);

  /*=========================================================
  =========================================================*/


  /*=========================================================
  VIEW/EDITOR DIALOG
  =========================================================*/
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    if (!openViewDialog) setSelectedItem(null);
  }, [openViewDialog]);

  /*=========================================================
  =========================================================*/

  /*=========================================================
  ROLE DYNAMIC FUNCTIONS
  =========================================================*/
  
  const role_id = cookies.get('role_id');

  /*=========================================================
  =========================================================*/
  
  return (
    <>
    <div id='AccountsList' className='ModuleContainer'>
      

        
      <div className='boxContainer'>

        <div className='boxContainerHeader'>
          <div className='boxContainerTitle'>
            <h2 className='h2_title'>Invoices</h2>
            <span className='h2_subtitle'>List</span>
          </div>
          <div className='boxContainerActions'>
           
          </div>
        </div>
          
            <Box id='invoices_list'  >
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Invoice Id</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Reseller Fee</TableCell>
                  {role_id == 2 && (
                    <TableCell>Status</TableCell>
                    )}
                  <TableCell align='right' className='td_buttons'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {invoices_list.map((invoices,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>
                        {invoices.invoice_id}
                      </TableCell>
                      <TableCell>{invoices.name} {invoices.last_name}</TableCell>

                     
                      <TableCell>
                        ${invoices.total_reseller_fee.toLocaleString()}
                      </TableCell>

                      {role_id == 2 && (
                      <TableCell>
                        <Chip
                          label={invoices.status_id == 1 ? 'Paid' : 'Unpaid'}
                          color={invoices.status_id == 1 ? 'info' : 'default'}
                          size="small"
                          variant="outlined"
                          sx={{ pl: 1, pr: 1 }}
                        />
                      </TableCell>
                      )}

                      <TableCell align='right' className='td_buttons'> 
                        <div className="actions-container">
                          {role_id != 2 && (
                          <>
                            {invoices.status_id != 1 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              startIcon={<Payment />}
                              onClick={() => {
                                setInvoiceSelectedToPay(invoices);
                                setOpenPaymentDialog(true);
                              }}
                            >
                              Pay
                            </Button>
                            ): (
                              <Typography variant="body2" color="text.secondary">
                                Already paid
                              </Typography>
                            )}
                          </>
                          )}

                          <Button
                            variant="contained"
                            color='primary'
                            size="small"
                            startIcon={<VisibilityIcon />}
                            onClick={() => {
                              // if (isMobile) {
                              //   navigate(`/invoices/details/${invoices.invoice_id}`);
                              // } else {
                                setSelectedItem(invoices);
                                setOpenViewDialog(true);
                              // }
                            }}
                          >
                            View
                          </Button>
                          </div>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          {total_pages > 1 && (
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
          )}
          </Box>
 
        </div>
    </div>

    {/* <AlertDialog 
    open={alertOpen} 
    setOpen={setAlertOpen} 
    send={deleteItem}
    subtitle="Are you sure you want to delete this invoices?"
    /> */}

    {invoiceSelectedToPay !== null &&
    <PaymentDialog
        open={openPaymentDialog}
        amount={invoiceSelectedToPay.invoice_total}
        metadata={{
            concept: 'invoice_payment',
            invoice_id: invoiceSelectedToPay.invoice_id,
            reseller_id: reseller_id,
        }}
        handleOpen={(e: any) => {
          console.log(e)
          setOpenPaymentDialog(e)
        }}
        onResponse={(e: any) => {
          if (e === 'succeeded') {
            enqueueSnackbar('Payment successful', { variant: 'success' });
            setRefreshList(refreshList + 1);
          } else {
            enqueueSnackbar('Payment failed', { variant: 'error' });
          }
          setOpenPaymentDialog(false);
        }}
    />
    }

    {!isMobile && (
    <Dialog
      open={openViewDialog}
      onClose={() => setOpenViewDialog(false)}
      maxWidth={'lg'}
      fullWidth={true}
    >
      <DialogContent sx={{p:0}}>
        {selectedItem !== null && (
        <InvoiceViewer
          setLoading={setLoading}
          invoice_id={selectedItem.invoice_id}
          isMobile={isMobile}
          asPopup={true}
          user_id={selectedItem.user_id}
          onSuccessfulPayment={() => {
            setRefreshList(refreshList + 1);
          }}
        />
        )}
      </DialogContent>
    </Dialog>
    )}

    </>
  );
});

