import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';
import Cookies from 'universal-cookie';
import EditIcon from '@mui/icons-material/Edit';
import AlertDialog from 'elements/AlertDialog';
import { useSnackbar, withSnackbar } from 'notistack';

import Filters from 'elements/Filters/Filters';
import TuneIcon from '@mui/icons-material/Tune';
import FiltersApplied from 'elements/Filters/FiltersApplied';

const cookies = new Cookies();

export default withSnackbar(
  function UserList(props: any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */


    /*=========================================================
  FILTERS
  =========================================================*/
  const filters = [
    {type: "text", label: "Email", name: "email", value: ""},
    {type: "text", label: "Name", name: "name", value: ""},
  ]
  
  const [filtersValues, setFiltersValues] = useState<any>([]);

  const setFilters = (tempFilters: any) => {
    setFiltersValues(tempFilters);
    //save in localstorage
    localStorage.setItem('filters', JSON.stringify(tempFilters));
  }
  /*=========================================================*/



  const {setLoading} = props;
 
  useEffect(() => {
    setLoading(false);
  }, []);

  const [user_list, setUserList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    loadList();
  }, [page, refreshList, filtersValues]);
  /*=========================================================*/

  const loadList = () => {

    setLoading(true);
  
    axios.get(ProjectConfig.api_url,
    {
      params: {
        request: 'user_list',
        params: {
          page: page,
          filters: filtersValues,
        }
      }
    })
      .then((response) => {
        console.log(response.data);
        setUserList(response.data.user_list)
        setTotalPages(response.data.total_pages)
        setLoading(false)
          
      })
      .catch((err) => {
        console.log(err.data);
      })
      localStorage.setItem('page', page.toString())
  }



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (user_id:number) => {

    setActiveItem(user_id);
    setAlertOpen(true);
    
  }
  /*=========================================================*/

// call global snackbar

const deleteItem = () => {

  axios.post(ProjectConfig.api_url, null, 
    {
      headers:{
        'LTKN': cookies.get('ltkn'),
      },
      params: {
        request: 'delete_user',
        user_id: activeItem
      }
    })

    .then((response) => {
      console.log(response.data);

      if(response.data.status == 'success'){
        enqueueSnackbar('User deleted successfully', { variant: 'success' });
      }else{
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }


      loadList();
    })
    .catch((err) => {
      console.log(err.data);
    })
}
  
const [alertOpen, setAlertOpen] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);

const [open, setOpen] = useState(false);
  
  return (
    <>
    <div id='ProductList'>
      
      <div className='buttons_top'>

      </div>


      <div id="userWorkstationsHeader" className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Users</h2>
              <span className="h2_subtitle">List of users</span>
            </div>
            
            <div className="boxContainerActions">
            <IconButton
                color="inherit"
                aria-label="menu"
                onClick={() => setOpen(true)}
              >
              <TuneIcon />
          </IconButton>
  
          <Filters open={open} setOpen={setOpen} filters={filters} setFilters={setFilters}/>
            </div>
            
        </div>
        <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues}/>
          
          <Box id='user_list'>
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Name / Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {user_list.map((user,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>
                        <AppAvatar name={user.name} image={user.image} />
                      </TableCell>
                      <TableCell>
                          <div className='td_column'>
                            <span className='td_column_title'>{user.name} {user.last_name}</span>
                            <span className='td_column_subtitle'>{user.email}</span>
                          </div>
                        
                      </TableCell>

                     
                      <TableCell>
                        {user.role_name}
                      </TableCell>

                      <TableCell> 
                        <Button
                          component={Link}
                          to={`/users/edit/${user.user_id}`}
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{marginRight: "10px"}}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>


                        <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(user.user_id)}>
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          {total_pages > 1 &&(
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
          )}
          </Box>
 
    </div>

    <AlertDialog 
    open={alertOpen} 
    setOpen={setAlertOpen} 
    send={deleteItem}
    subtitle="Are you sure you want to delete this user?"
    />

    </>
  );
});

