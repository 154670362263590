import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

interface Props {
  name: any;
  image?: any;
  size?: any;
}

export default function AppAvatar(props: Props) {

  const size = props.size ? props.size : 56;

  //GET FIRST LETTER OF NAME IN UPPERCASE
  const firstLetter = props.name? props.name.charAt(0).toUpperCase() : 'F';
  //GET FIRST LETTER OF LAST NAME IF NAME HAS MORE THAN ONE WORD
  const secondLetter = props.name? props.name.split(' ').length > 1 ? props.name.split(' ')[1].charAt(0).toUpperCase(): '' : '';

  return (
    <Avatar
      sx={{width: size, height: size}}
      children = {firstLetter+secondLetter}
      src={props.image}
    />
  );
}