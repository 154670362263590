import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Button, Chip, Dialog, DialogContent, DialogContentText, Icon, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {ProjectConfig} from 'Global';
import Cookies from 'universal-cookie';
import ic_empty from 'icons/ic_empty.svg';
import RefreshIcon from '@mui/icons-material/Refresh';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSnackbar } from 'notistack';
import AlertDialog from 'elements/AlertDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const cookies = new Cookies();

export default function BlockDetails(props: any) {
  
  const {setLoading, open, setOpen, blockId} = props;
  const [alertOpen, setAlertOpen] = useState(false);

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */


  useEffect(() => {
    setLoading(false);
    if(blockId != 0){
      loadBlock();
    }
  }, [blockId])

  const [block, setBlock] = useState<any>({});
  console.log("bloque",block);

  const loadBlock = () => {
    setLoading(true);
    axios.get(ProjectConfig.api_url,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'get_block_details',
          params: {
            id: blockId
          },
        }
      })
        .then((response) => {
          console.log("block",response.data);
          setBlock(response.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err.data);
        })
    
  }



  const cancelOffer = () => {
    setLoading(true);
    axios.post(ProjectConfig.api_url, null,
      {
        headers:{
          'LTKN': cookies.get('ltkn'),
        },
        params: {
          request: 'cancel_offer',
          account_id: block.account_id,
          pickUpTime: block.startDateUnix,
        }
      })
        .then((response) => {
          if(response.data.status == 'success'){
            loadBlock();
          }

          enqueueSnackbar(response.data.message, { variant: response.data.status });
          setLoading(false);
           
        })
        .catch((err) => {
          console.log(err.data);
        })
  }


  
  return (
    <>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
          <DialogContent  className="forced_box_editor">

            <div className='bd_serviceAreaname'>
              {block.serviceAreaName}
            </div>
            
            <div className='bd_header'>
              <div className='bd_header_left'>
                <span className='bd_price_label'>Price</span>
                <span className='bd_price'>${block.price_amount}</span>
              </div>
              <div className='bd_header_right'>
                <Chip label={block.offer_status} color="primary" variant='outlined' size='small' />
              </div>
            </div>

            

            <div className='bd_body'>
              <div className='bd_body_section'>
                <div className='bd_body_icon'>
                <CalendarMonthIcon color='primary' />
                </div>
              </div>
              <div className='bd_body_section'>
                <div className='bd_body_date'>
                  <span className='bd_body_label'>Start</span>
                  <span className='bd_body_value'>{block.start_date}</span>
                  <span className='bd_body_label'>{block.start_time}</span>
                </div>

                <div className='bd_body_arrow'>
                  <ArrowForwardIosIcon color='primary' />
                </div>

                <div className='bd_body_date alignRight'>
                  <span className='bd_body_label'>End</span>
                  <span className='bd_body_value'>{block.end_date}</span>
                  <span className='bd_body_label'>{block.end_time}</span>
                </div>

              </div>

              <div className='bd_body_section alignRight'>
                <div>
                  <span className='bd_body_label'>Total Time</span>
                  <span className='bd_body_value'>{block.total_hours} H</span>
                </div>
              </div>

              

            </div>
          {block.offer_status_id == 2 &&
          <div className="cancelButton">
            <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setAlertOpen(true)}
                  startIcon={<DeleteForeverIcon />}
              >
                  Forfeit Block
              </Button>
          </div>
          }
          

          <div className="buttons">
              <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(false)}
              >
                  Close
              </Button>
              
             

          </div>

          </DialogContent>
      </Dialog>


      <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={cancelOffer}
        subtitle="Are you sure you want to cancel this offer?"
      />
       
    </>
  );
};

