import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, Button, CircularProgress, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AppAvatar from 'elements/AppAvatar';

export default function AmazonAccountsListBlocks(props: any) {

  useEffect(() => {
    props.setLoading(false);
  }, []);
  
  const setLoading = props.setLoading;

  const [account_list, setAccountList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);
  const currentPage=parseInt(localStorage.getItem('page') || "1");
  const [page, setPage] = useState(currentPage);

  // BLOCKS LIST
  const [blocks_list, setBlocksList] = useState<any []>([]);
  const [total_pages_blocks, setTotalPagesBlocks] = useState(0);
  const currentPageLog=parseInt(localStorage.getItem('pageLog') || "1");
  const [pageLog, setPageLog] = useState(currentPage);


  const [refreshList, setRefreshList] = useState(0);


  /*=========================================================
  LOAD LIST            
  =========================================================*/

  useEffect(() => {
    
      axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'af_accounts_list',
          page: page,
        }
      })
        .then((response) => {
          
          setAccountList(response.data.account_list)
          setTotalPages(response.data.total_pages)
          setLoading(true)
           
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('page', page.toString())
    
  }, [page, refreshList])
  /*=========================================================*/



  const getBlocksLog = () => {
    setLoading(true)
    axios.get(ProjectConfig.api_url,
      {
        params: {
          request: 'get_blocks_log',
          params: {
            page: pageLog,
          },
        }
      })
        .then((response) => {
          console.log(response.data);
          setBlocksList(response.data.list)
          setTotalPagesBlocks(response.data.total_pages)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err.data);
        })
        localStorage.setItem('pageLog', pageLog.toString())
  }

  useEffect(() => {
    getBlocksLog();
  }, [pageLog])


  
  return (
    <div id='blocksContainer' className='module_container'>
      
      <div className='buttons_top'>
        <Button component={Link} to="add" variant="contained">
          New Account
        </Button>

      </div>
          
            <Box id='account_list' title="Accounts" subtitle="List"  >
            
            <TableContainer>
            <Table stickyHeader aria-label="sticky table"> 
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Account name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {account_list.map((account,i) => (
                    <TableRow 
                      
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell onClick={()=> {window.location.href = `amazon-accounts/edit/${account.account_id}`}} >
                        <AppAvatar name={account.name} image={account.image} />
                      </TableCell>
                      <TableCell onClick={()=> {window.location.href = `amazon-accounts/edit/${account.account_id}`}} >{account.name}</TableCell>

                     

                      <TableCell> 
                        <Button
                          variant="contained"
                          onClick={()=> {window.location.href = `amazon-blocks/get/${account.account_id}`}}
                        >
                          Get blocks
                        </Button>

                        <Button
                          variant="contained"
                          onClick={()=> {window.location.href = `schedule/${account.account_id}`}}
                        >
                          Schedule
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <Pagination count={total_pages} page={page}  onChange={(event, value) => {
            setPage(value)
          }
          } 
          color="primary"
          style={{"marginTop": "20px","alignSelf": "center"}}
          />
          </Box>
          

          <Box id='blocksList' title="Blocks Log" subtitle="List"  >
            <Button
              variant="contained"
              onClick={()=> {getBlocksLog()}} 
            >
              Refresh
            </Button>

            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Projected Tips</TableCell>
                  <TableCell>Rate Per Hour</TableCell>
                  <TableCell>Total time</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {blocks_list.map((block,i) => (
                    <TableRow
                      key={i}
                      hover
                      role="checkbox"
                    >
                      <TableCell>{block.email}</TableCell>
                      <TableCell>{block.start_time} to {block.end_time}</TableCell>
                      <TableCell>$ {block.price_amount.toLocaleString()}</TableCell>
                      <TableCell>$ {block.projected_tips.toLocaleString()}</TableCell>
                      <TableCell>$ {block.rate_per_hour.toLocaleString()}</TableCell>
                      <TableCell>{block.total_minutes}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Pagination 
              count={total_pages_blocks} page={pageLog}  onChange={(event, value) => {
              setPageLog(value)}} 
              color="primary"
              style={{"marginTop": "20px","alignSelf": "center"}}
            />
          </Box>
    </div>
  );
};

