import React, { Component } from 'react'



export const ProjectConfig = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?{
  api_url: "http://simpleflex.localhost.com/api/",
  flexapi : "https://flexapi.easyflexdriver.com",
  google: {
    isActive: true,
    instance: 'instance-2',
    zone: 'us-east1-b',
    client_id: '144035603049-kd83tnjeqgheik6i0keinkukkivpsrmo.apps.googleusercontent.com',
    project_id: 'woven-honor-392118',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_secret: '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',
    redirect_uris: ['https://easyflexdriver.com/google-receiver'],
  },
  STRIPE_PUBLIC_KEY: "pk_live_51OIAZJEzZTJR8PlNrdMsolmKJ8gva8oYODLB5IQJdAgFJrBIBcPZFX5wWmky4PqVF8p6nw4C07lol5iP2mMlmgtz00qFw0j7yu",
}
:
{
  api_url: "https://api.easyflexdriver.com/api/",
  flexapi : "https://flexapi.easyflexdriver.com",
  google: {
    isActive: true,
    instance: 'instance-2',
    zone: 'us-east1-b',
    client_id: '144035603049-kd83tnjeqgheik6i0keinkukkivpsrmo.apps.googleusercontent.com',
    project_id: 'woven-honor-392118',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_secret: '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',
    redirect_uris: ['https://easyflexdriver.com/google-receiver'],
  },
  STRIPE_PUBLIC_KEY: "pk_live_51OIAZJEzZTJR8PlNrdMsolmKJ8gva8oYODLB5IQJdAgFJrBIBcPZFX5wWmky4PqVF8p6nw4C07lol5iP2mMlmgtz00qFw0j7yu",
} 





export const UserTheme = {

  /*===============================
  SIMPLEFLEX 
  ================================== */


  // typography: {
  //   fontFamily: [
  //     'Poppins',
  //     'sans-serif'
  //   ].join(','),
  //   fontSize: 13,
  //   fontWeightLight: 300,
  //   fontWeightRegular: 300,
  //   fontWeightMedium: 300,
  //   fontWeightBold: 700,
  // },
  // palette: { 
  //   primary: {
  //     light: '#eb5c24',
  //     main: '#eb5c24',
  //     dark: '#84300f',
  //     contrastText: '#fff',
  //   },
  //   secondary: {
  //     light: '#e69203',
  //     main: '#e69203',
  //     dark: '#cb8308',
  //     contrastText: '#fff',
  //   },
  //   error: {
  //     main: '#f44336',
  //     contrastText: '#fff',
  //   },
  //   background: {
  //     default: '#fafafa',
  //   },
  // },


  /*===============================
  EASY FLEX 
  ================================== */


  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 300,
    fontWeightBold: 700,
  },
  palette: { 
    primary: {
      light: '#0053c3',
      main: '#0053c3',
      dark: '#003479',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e69203',
      main: '#e69203',
      dark: '#cb8308',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    background: {
      default: '#fafafa',
    },
  },
  

}

