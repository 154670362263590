import { Button, Chip, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { ProjectConfig } from "Global";
import axios from "axios";
import AlertDialog from "elements/AlertDialog";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import AddTime from './AddTime';

  export default function TimeForm(props: any) {

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */
    
  const {setLoading, open, setOpen, loadList, setAccountId,account} = props;
  const [idSelected, setIdSelected] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);

  const defaultRunningConfig = {
    rate_sleep: 0,
    sleep_time: 0,
    stop_minutes: 0,
    running_minutes: 0,
    }


  const [runningConfig, setRunningConfig] = useState<any>(defaultRunningConfig);

  useEffect(() => {
    if (Object.keys(account).length > 0) {
        console.log("asdsadas",account);
       setRunningConfig(account.running_config);
    }
    }, [account])


    const updateTimeConfig = () => {
        
        setLoading(true)
        axios.post(ProjectConfig.api_url, null,
        {
            params: {
                request: 'update_running_config',
                params: {
                    account_id: account.account_id,
                    runningConfig: runningConfig
                }
            }
        })
            .then((response) => {
            console.log(response.data);
            if (response.data.status == 'success') {
                enqueueSnackbar('Configuration Updated', {variant: 'success'});
                
            } else {
                enqueueSnackbar('Error', {variant: 'error'});
            }
            setLoading(false)
            loadList();
            setOpen(false)
            })
            .catch((err) => {
            console.log(err.data);
            })

    }
  
  return (
    <>

    <Dialog
        open={open}
        onClose={() => {
            setOpen(false)
            setAccountId(0);
            loadList();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >  
        <DialogContent  className="forced_box_editor">
            <div className="dialog_header">
                <h2 className='dialog_h2'>Time Configuration</h2>
                
            </div>
            <div className="form">


                
                <TextField
                    id="outlined-basic"
                    label="Rate Sleep"
                    variant="outlined"
                    value={runningConfig.rate_sleep}
                    onChange={(e) => setRunningConfig({...runningConfig, rate_sleep: e.target.value})}
                />
                <TextField
                    id="outlined-basic"
                    label="Speed (Microseconds)"
                    variant="outlined"
                    value={runningConfig.sleep_time}
                    onChange={(e) => setRunningConfig({...runningConfig, sleep_time: e.target.value})}
                    //endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    InputProps={{
                        endAdornment: (
                            <span style={{fontSize: '12px', color: '#ccc', fontWeight: 500, whiteSpace: 'nowrap'}}>
                                {runningConfig.sleep_time / 1000000} s
                            </span>
                        )
                      }}
                />
                <TextField
                    id="outlined-basic"
                    label="Stop Minutes"
                    variant="outlined"
                    value={runningConfig.stop_minutes}
                    onChange={(e) => setRunningConfig({...runningConfig, stop_minutes: e.target.value})}
                />
                <TextField
                    id="outlined-basic"
                    label="Running Minutes"
                    variant="outlined"
                    value={runningConfig.running_minutes}
                    onChange={(e) => setRunningConfig({...runningConfig, running_minutes: e.target.value})}
                    

                />
                


            </div>
            
            
            

            <div className="buttons">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setOpen(false);
                        setAccountId(0);
                        loadList();
                    }}
                >
                    
                    Close
                </Button>


                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAlertOpen(true)}
                >
                    Update
                </Button>

            </div>
        </DialogContent>
            
    </Dialog>



    <AlertDialog 
        open={alertOpen} 
        setOpen={setAlertOpen} 
        send={updateTimeConfig}
        subtitle="Are you sure you want to delete this order?"
        
      />



    </>
  );
}

