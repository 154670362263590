import { Autocomplete, Button, createFilterOptions, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import {ProjectConfig} from 'Global';



 //TODAY DATE FORMATED MM/DD/YYYY
 const today = new Date('2014-08-18T21:11:54')


 export default function AmazonBlocks(props: any) {

  useEffect(() => {
    props.setLoading(false);
  }, []);


  const [offerList, setOfferList] = useState<any>([]);
  

  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {account_id } = useParams();
  account_id = props.account_id ? props.account_id : account_id;
  /*=======================================
  =======================================*/


  const getBlocks = () => {
    console.log("getBlocks");
    axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "get_blocks",
            account_id: account_id,
          },
        })
        .then((response) => {
          if(response.data.offerList.length > 0){
            stopLoop();
          }
          console.log(response.data.offerList);
          setOfferList(response.data.offerList);
        })
        .catch((err) => {
          console.log("err",err.data);
        });
    }

    
    const acceptOffer = (offerId: any) => {

      props.setLoading(false);
      axios
          .get(ProjectConfig.api_url, {
            params: {
              request: "accept_offer",
              account_id: account_id,
              offerId: offerId,
            },
          })
          .then((response) => {
            console.log(response);
            props.setLoading(false);
          }
          )
          .catch((err) => {
            console.log(err.data);
            props.setLoading(false);
          }
          );
    }



    // //LOOP REPEAT FUNCTION EVERY 2 SECONDS
    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     getBlocks();
    //     //STOP LOOP IF offerList IS NOT EMPTY
    //     if(offerList.length > 0){
    //       clearInterval(interval);
    //     }
    //   }, 2000);
    //   return () => clearInterval(interval);
    // }, []);


    //INTERVAL LOOP GLOBAL VARIABLE

    const [stop, setStop] = useState(false);


    const startLoop = () => {
      getBlocks();
    }

    const stopLoop = () => {
      setStop(true);
    }



  return (
    <Fragment>

      <div className="buttonsBar">
      <Button
        variant="contained"
        color="primary"
        onClick={getBlocks}
      >
        Start
      </Button>

      <Button
        variant="outlined"
        color="primary"
        onClick={stopLoop}
      >
        Stop
      </Button>
      </div>

      <Box id='blocksList' title="Blocks" subtitle="List"  >

          {offerList.length > 0 ?  offerList.map((offer: any, index: any) => {
            return (
              <div className="AFBlock" key={index}>
                <div className="AFBlockLeft">
                  <div className="AFBlockRow">
                    <span>
                      Price Amount
                    </span>
                    <span>
                      {offer.rateInfo.priceAmount}
                    </span>
                  </div>

                  <div className="AFBlockRow">
                    <span>
                      Projected Tip
                    </span>
                    <span>
                      {offer.rateInfo.projectedTips}
                    </span>
                  </div>
                </div>
                <div className="AFBlockRight">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      props.setLoading(true);
                      acceptOffer(offer.offerId);
                    }}
                  >
                    Accept Block
                  </Button>
                </div>


                
              </div>
            );
          })
          :
          <div>
            No Blocks
          </div>
        }
      </Box>


    </Fragment>
    
  );
};