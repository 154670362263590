import { Button, IconButton } from '@mui/material';
import { useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

interface Props {
    id?:string,
    className?:string,
    title?:string,
    subtitle?:string,
    children?: React.ReactNode;
}    

const Box = (props: Props) => {

  const className = props.className ? " "+props.className : "";
  const id = props.id ? props.id : "";


  const box_header = props.title? 
  <div className='boxContainerHeader'>
    <div className='boxContainerTitle'>
      <h2 className='h2_title'>{props.title}</h2>
      <span className='h2_subtitle'>{props.subtitle}</span>
    </div>
  </div> 
  : null;
  
  

  return (
    <div id={id}>
      <div className="box_container">
        {box_header}

        <div className={"box"+className}>
          <div className="box_children">

            

            <div className="box_content">
              {props.children}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};



export default Box;