import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css';
import './assets/css/mobile.css';
import MainMenu from './Menu'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider, useSnackbar } from 'notistack';
import AppRoutes from 'AppRoutes';
import {BrowserRouter, BrowserRouter as Router , useLocation, useSearchParams} from 'react-router-dom'

import Login from 'components/Login/Login';
import Cookies from 'universal-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import AccountMenu from 'components/Account/AccountMenu';
import { ProjectConfig, UserTheme } from 'Global';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';


const cookies = new Cookies();

const theme = createTheme(UserTheme);

const isMobileDefault = window.innerWidth < 768;

const Site = () => {
  const childRef = useRef();
  const userIsLogged = cookies.get('ltkn') !== undefined && cookies.get('role_id') !== "undefined";

  
  const validateToken = async () => {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "validateToken",
            ltkn: cookies.get("ltkn"),
          },
        })
        .then((response) => {
          if (response.data.status === "error") {

            /* localStorage.setItem("user_id", user_data.user_id);
          localStorage.setItem("user_name", user_data.user_name);
          localStorage.setItem("user_image", user_data.image);
          localStorage.setItem("role_id", user_data.role_id);*/

            //remove local storage
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_name");
            localStorage.removeItem("user_image");
            localStorage.removeItem("role_id");


            cookies.remove("ltkn");
            cookies.remove("role_id");
            cookies.remove("user_id");
            cookies.remove("username");
            cookies.remove("email");
            cookies.remove("ref");
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err.data);
        });
    };

  /*=======================================
  =======================================*/


  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(isMobileDefault);
  const [openDrawer, setOpenDrawer] = useState(false);

  const location = useLocation();
  const isHome =location.pathname === "/"? "home" : "";


  //if url contains ref=xxx
  const params = new URLSearchParams(location.search);
  const ref = params.get('ref');
  if(ref !== null){
    cookies.set('ref', ref, { path: '/' });
  }


  //CHECK WINDOW SIZE LIVE 
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);



  useEffect(() => {
    validateToken();
  }
  , [location]);




    return (
    <ThemeProvider theme={theme} >
      <SnackbarProvider maxSnack={3}>
        <div id='loader' className={!loading? 'loaderHide' : ''}>
          <CircularProgress />
        </div>

        {//  userIsLogged ? REDIRECT TO LOGIN
        !userIsLogged? (
          <Login setLoading={setLoading} />
        ) : (
          <>
          <div id='structure'>
            <MainMenu openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} isMobile={isMobile} />
            <div id="main-container"> 
              <div id='top_bar'>
                  <div id='top_bar_left'>
                    
                    {isMobile && (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      onClick={() => setOpenDrawer(true)}
                      sx={{ ...(openDrawer && { display: 'none' }) }}
                    >
                      <MenuIcon />
                    </IconButton>
                    )}

                  </div>
                  <div id='top_bar_right'>
                    <AccountMenu />
                  </div>
              </div>
              <div id="main-content">
                <AppRoutes setLoading={setLoading} isMobile={isMobile}/>
              </div>
            </div>
          </div>
          </>
        )}
      </SnackbarProvider>
    </ThemeProvider>
    );
  
}

// ========================================
 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Router>
      <Site />
    </Router>
);