import {
  Autocomplete,
  Button,
  Chip,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  styled,
  Switch,
  SwitchProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from "icons/ic_dots.svg";
import { ProjectConfig } from "Global";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import EventIcon from "@mui/icons-material/Event";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Cookies from "universal-cookie";

import TimerIcon from "@mui/icons-material/Timer";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import AccountMenu from "components/AmazonAccounts/AccountMenu";
import { useSnackbar } from "notistack";
import moment from "moment";
import PaymentDialog from "elements/PaymentGateways/Stripe/StripeDialog";
import { Payment } from "@mui/icons-material";
import AlertDialog from "elements/AlertDialog";

const defaultDetails: any = {
  invoice_id: '',
  invoice_total: '',
  created_at: moment().format('DD-MM-YYYY'),
  status_id: '',
  name: '',
  last_name: '',
};

const cookies = new Cookies();

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

//TODAY DATE FORMATED MM/DD/YYYY
const today = new Date("2014-08-18T21:11:54");

export default function InvoiceViewer(props: any) {
  
  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {invoice_id: invoiceIdURLParams } = useParams();
  /*=======================================
  =======================================*/

  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  const {
    invoice_id: invoiceIdProps,
  } = props;
  /*=======================================
  =======================================*/

  const [invoice_id, setInvoiceId] = useState(invoiceIdURLParams ? invoiceIdURLParams : invoiceIdProps);

  useEffect(() => {
    if (invoiceIdURLParams) {
      setInvoiceId(invoiceIdURLParams);
    }
  }, [invoiceIdURLParams]);

  useEffect(() => {
    if (invoiceIdProps) {
      setInvoiceId(invoiceIdProps);
    }
  }, [invoiceIdProps]);


  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */
    
  const {
    setLoading,
    open,
    setOpen,
    account_id,
    loadList,
    setAccountId,
    reseller_id,
    user_id,
    asPopup = false,
    onSuccessfulPayment = () => {},
  } = props;

  const role_id = cookies.get('role_id');
  const isReseller = reseller_id ? true : false;

  const [openAddTime, setOpenAddTime] = useState(false);
  const [list, setList] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const [formDataDetails, setFormDataDetails] = useState<any>({...defaultDetails});

  const [total_pages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

    useEffect(() => {
        if (account_id != 0) {
         loadOrders();
         loadDetails();
        } else {
          setList([]);
          setData({});
          setFormDataDetails({...defaultDetails});
        }
    }, [account_id, invoice_id]);
    

    const loadOrders = () => {
        setLoading(true)
        axios.get(ProjectConfig.api_url,
        {
            params: {
                request: 'get_invoices_orders',
                params: {
                    invoice_id: invoice_id,
                    full_list: true,
                }
            }
        })
            .then((response) => {
            console.log("orders",response.data);
            setData(response.data)
            setList(response.data.list)
            setTotalPages(response.data.total_pages)
            setLoading(false)
            })
            .catch((err) => {
            console.log(err.data);
            })
    }

    const [idSelected, setIdSelected] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);

    const loadDetails = () => {
      setLoading(true)

      console.log("account_id",account_id);
      
      axios.get(ProjectConfig.flexapi + '/invoices/details', {
        params: {
          user_id,
          invoice_id,
        },
      })
        .then((response) => {
          console.log("details",response.data);
          setFormDataDetails({...response.data.data});
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.data);
        })
    }

  /*=========================================================
  PAYMENT INVOICE WITH STRIPE
  =========================================================*/
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  /*=========================================================
  =========================================================*/


  /*=========================================================
  PAYMENT INVOICE AS ADMIN
  =========================================================*/
  const [openPaymentDialogAlert, setOpenPaymentDialogAlert] = useState(false);

  const handlePaymentInvoiceAsAdmin = () => {
    setLoading(true);

    axios.post(ProjectConfig.api_url, null,{
      params: {
        request: 'pay_as_admin',
        params: {
          invoice_id: invoice_id,
        }
      }
    })
      .then((response) => {
        console.log(response.data);
        enqueueSnackbar('Invoice paid successfully', { variant: 'success' });
        loadDetails();
        onSuccessfulPayment();
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => {
        setLoading(false);
      })
  }

    

  return (
    <>
      <div id="ResellerEditor" className={asPopup ? "" : "ModuleContainer"}>
        <div className="userForm_right">       
        </div>

        <Box title={asPopup ? undefined : "Invoice"} subtitle={asPopup ? undefined : "Editor"} className={asPopup ? "box-shadow-off" : ""}>
          {asPopup && (
          <div className="boxContainerHeader">
            <div className='boxContainerTitle'>
              <h2 className='h2_title'>Invoice</h2>
              <span className='h2_subtitle'>Editor</span>
            </div>

            <div className='boxContainerActions'>
              {formDataDetails.status_id != 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Payment />}
                  onClick={() => {
                    if (role_id == 2) {
                      setOpenPaymentDialogAlert(true);
                    } else {
                      setOpenPaymentDialog(true);
                    }
                  }}
                >
                  {role_id != 2 ? 'Pay' : 'Pay as Admin'}
                </Button>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Paid
                </Typography>
              )}
            </div>
          </div>
          )}
          
          <div className="">
            <div className="data_row">
              <span className="data_title">User:</span>
              <span className="">{formDataDetails.name} {formDataDetails.last_name}</span>
            </div>

            <div className="data_row">
              <span className="data_title">Created at:</span>
              <span className="">{moment(formDataDetails.created_at).format('DD-MM-YYYY')}</span>
            </div>

            <div className="data_row">
              <span className="data_title">Invoice Total:</span>
              <span className="">${formDataDetails.invoice_total}</span>
            </div>
          </div>
        </Box>
        <Box title={asPopup ? undefined : "Orders"} subtitle={asPopup ? undefined : "List"} className={asPopup ? "box-shadow-off" : ""}>
          {asPopup && (
            <div className="boxContainerHeader">
              <div className='boxContainerTitle'>
                <h2 className='h2_title'>Orders</h2>
                <span className='h2_subtitle'>List</span>
              </div>
            </div>
          )}
          <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Order ID
                    </TableCell>
                    <TableCell>
                        User / Date
                    </TableCell>
                    <TableCell>
                        Days
                    </TableCell>
                    <TableCell>
                        Order Price
                    </TableCell>
                    <TableCell>
                        Reseller Fee
                    </TableCell>
                    <TableCell>
                        Earnings
                    </TableCell>
                </TableRow>
            </TableHead>
                <TableBody>
                    {list.map((item:any) => (
                        <TableRow key={item.order_id}>
                            <TableCell>
                                {item.order_id}
                            </TableCell>
                            <TableCell>
                            <div className='flexTd'>
                              <span className='flexTd_bold'>{item.email}</span>
                              <span className='flexTd_light'>{item.date}</span>
                            </div>
                            </TableCell>
                            <TableCell>
                                <Chip
                                    label={item.days}
                                    color="primary"
                                />
                            </TableCell>
                            <TableCell>
                                ${item.order_price}
                            </TableCell>
                            <TableCell>
                                ${item.reseller_fee}
                            </TableCell>
                            <TableCell>
                                ${item.order_price - item.reseller_fee}
                            </TableCell>
                        </TableRow>
                    ))}

                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell><span className='flexTd_bold'>Total</span></TableCell>
                      <TableCell>
                            <div className='flexTd'>
                                <span className='flexTd_bold'>${data.total_reseller_fee}</span>
                            </div>
                      </TableCell>

                        <TableCell>
                            <div className='flexTd'>
                                <span className='flexTd_bold'>${list.reduce((a:any, b:any) => a + (b['order_price'] - b['reseller_fee']), 0)}</span>
                            </div>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </Box>
      </div>
      
      {(role_id != 2 && formDataDetails.status_id != 1) &&
      <PaymentDialog
          open={openPaymentDialog}
          amount={formDataDetails.invoice_total}
          metadata={{
              concept: 'invoice_payment',
              invoice_id: formDataDetails.invoice_id,
              reseller_id: reseller_id,
          }}
          handleOpen={(e: any) => {
            console.log(e)
            setOpenPaymentDialog(e)
          }}
          onResponse={(e: any) => {
            if (e === 'succeeded') {
              enqueueSnackbar('Payment successful', { variant: 'success' });
              loadDetails();
              onSuccessfulPayment();
            } else {
              enqueueSnackbar('Payment failed', { variant: 'error' });
            }
            setOpenPaymentDialog(false);
          }}
      />
      }

      <AlertDialog
        subtitle="Are you sure you want to pay this invoice?"
        open={openPaymentDialogAlert}
        setOpen={setOpenPaymentDialogAlert}
        send={() => {
          handlePaymentInvoiceAsAdmin();
        }}
      />
    </>
  );
}
