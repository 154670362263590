import * as React from "react";
import {
  Link,
  Avatar,
  Button,
  Container,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Cookies from "universal-cookie";
import Box from "elements/Box";
import { ProjectConfig } from "Global";
import axios from "axios";
import { useSnackbar, withSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Landing } from "./Landing/Landing";

const cookies = new Cookies();

export default withSnackbar(function FormLogin(props: any) {
  const { loading, logo, setLoading, setState, email, setEmail } = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  const [loadingButton, setLoadingButton] = useState(false);

  const defaultValues: any = {
    name: "",
    lastname: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
  };

  const [formValues, setFormValues] = React.useState(defaultValues);

  const [openRegister, setOpenRegister] = React.useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setFormValues(defaultValues);
  }, [openRegister]);

  //IF TOKEN MOBILE APP EXISTS IN URL
  const tokenApp = localStorage.getItem("tokenApp")
    ? localStorage.getItem("tokenApp")
    : "";

  const handleInputChange = (e: {
    target: { name: any; value: any; required: any };
  }) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  /*================================================
  SIGN IN (SUBMIT)
  ================================================*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoadingButton(true);

    sign_in(data.get("email"), data.get("password"));
  };

  /*================================================
  ================================================*/

  const sign_in = (email: any, password: any) => {
    axios
      .get(ProjectConfig.api_url, {
        params: {
          request: "sign_in",
          email: email,
          password: password,
          tokenApp: "",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          enqueueSnackbar(response.data.message, { variant: "success" });
          const user_data = response.data.user_data;
          console.log(user_data);

          /*================================================
            SET COOKIES
            ================================================*/
          cookies.set("ltkn", user_data.ltkn, {
            path: "/",
            maxAge: 86400 * 365,
          });
          cookies.set("user_id", user_data.user_id, {
            path: "/",
            maxAge: 86400 * 365,
          });
          cookies.set("user_name", user_data.user_name, {
            path: "/",
            maxAge: 86400 * 365,
          });
          cookies.set("role_id", user_data.role_id, {
            path: "/",
            maxAge: 86400 * 365,
          });
          /*================================================
            ================================================*/

          localStorage.setItem("ltkn", user_data.ltkn);
          localStorage.setItem("user_id", user_data.user_id);
          localStorage.setItem("user_name", user_data.user_name);
          localStorage.setItem("user_image", user_data.image);
          localStorage.setItem("role_id", user_data.role_id);

          window.location.reload();
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
          setLoadingButton(false);
        }
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const checkEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  /*================================================
  SIGN UP (SUBMIT)
  ================================================*/
  async function handleRegister() {
    if (
      formValues.name === "" ||
      formValues.lastname === "" ||
      formValues.email === "" ||
      formValues.password === "" ||
      formValues.password_confirmation === "" ||
      formValues.phone === ""
    ) {
      //snackbar error
      enqueueSnackbar("Todos los campos son obligatorios", {
        variant: "error",
      });
      return;
    }

    if (
      formValues.password !== "" &&
      formValues.password !== formValues.password_confirmation
    ) {
      //snackbar error
      enqueueSnackbar("Las contraseñas no coinciden", { variant: "error" });
      return;
    }

    if (!checkEmail(formValues.email)) {
      //snackbar error
      enqueueSnackbar("El correo electrónico no es válido", {
        variant: "error",
      });
      return;
    }

    console.log(formValues);
    setLoading(true);

    if (cookies.get("ref") !== undefined) {
      formValues.ref = cookies.get("ref");
    } else {
      formValues.ref = "";
    }

    axios
      .post(ProjectConfig.api_url, null, {
        params: {
          request: "sign_up",
          formdata: formValues,
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.status === "success") {
          enqueueSnackbar(response.data.message, { variant: "success" });
          sign_in(formValues.email, formValues.password);
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.data);
      });

    return;
  }

  return (
    <>
      <Box id="loginBox">
        <img className="loginLogo" src="/images/logo.png"></img>

        <p>Enter your credentials to access your account</p>

        <form onSubmit={handleSubmit} className="form">
          <TextField
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            type="text"
            autoComplete="email"
            autoFocus
          />
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <LoadingButton
            size="small"
            type="submit"
            loading={loadingButton}
            fullWidth
            variant="contained"
            sx={{ fontSize: "13px", padding: "10px 0px" }}
          >
            Log in
          </LoadingButton>

          <Button
            variant="outlined"
            fullWidth
            sx={{ fontSize: "13px", padding: "10px 0px" }}
            onClick={() => {
              setOpenRegister(true);
            }}
          >
            Sign Up
          </Button>

          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setState("forgotPass");
            }}
          >
            Forgot password?
          </Link>
        </form>
      </Box>

      <Dialog
        open={openRegister}
        onClose={() => {
          setOpenRegister(false);
        }}
      >
        <DialogContent>
          <h2>Register</h2>
          <p>Complete the following form to create your account</p>
          <form className="form" id="registerForm">
            <div className="input_row">
              <TextField
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                type="text"
                value={formValues.name}
                autoComplete="name"
                onChange={handleInputChange}
              />

              <TextField
                required
                fullWidth
                id="lastname"
                label="Lastname"
                name="lastname"
                type="text"
                autoComplete="lastname"
                onChange={handleInputChange}
                value={formValues.lastname}
              />
            </div>

            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="text"
              value={formValues.email}
              autoComplete="email"
              onChange={handleInputChange}
              error={formValues.email !== "" && !checkEmail(formValues.email)}
              helperText={
                formValues.email !== "" && !checkEmail(formValues.email)
                  ? "Invalid email"
                  : ""
              }
            />

            <TextField
              required
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              type="number"
              value={formValues.phone}
              autoComplete="phone"
              onChange={handleInputChange}
            />

            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              value={formValues.password}
              autoComplete="current-password"
              onChange={handleInputChange}
              // eye icon to show password
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              required
              fullWidth
              name="password_confirmation"
              label="Repeat Password"
              type={showPassword ? "text" : "password"}
              id="password_confirmation"
              value={formValues.password_confirmation}
              onChange={handleInputChange}
              error={
                formValues.password_confirmation != "" &&
                formValues.password !== formValues.password_confirmation
              }
              helperText={
                formValues.password_confirmation != "" &&
                formValues.password !== formValues.password_confirmation
                  ? "Passwords do not match"
                  : ""
              }
              // eye icon to show password
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button fullWidth variant="contained" onClick={handleRegister}>
              Sign Up
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
});
