import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie';



const cookies = new Cookies();

export default function GoogleReceiver(props: any) {

  const [code, setCode] = useState<any>(null);

  useEffect(() => {
    updateGoogleAccessToken();
  }, []);
  
  const updateGoogleAccessToken = () => {

    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    setCode(code);
    console.log("code",code);

    axios.post(ProjectConfig.flexapi+"/offers/updateGoogleAccessToken",
    {
      code: code,
    })
      .then((response) => {
        console.log("res",response.data);

        window.close();

      })
      .catch((err) => {
        console.log(err);
      })
  }

  
  return (
    <>
  
    </>
  );
}


