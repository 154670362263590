import { Autocomplete, Button, Checkbox, createFilterOptions, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "elements/Box";
import ic_dots from 'icons/ic_dots.svg';
import {ProjectConfig} from 'Global';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useSnackbar } from "notistack";

 //TODAY DATE FORMATED MM/DD/YYYY
 const today = new Date('2014-08-18T21:11:54')


 export default function PlansEditor(props: any) {

  const {setLoading} = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /*============================================= */

  useEffect(() => {
    setLoading(false);
  }, []);
  

  /*=======================================
    GET USER ID FROM URL OR FROM ACCOUNT USER ID
  =======================================*/
  let {plan_id } = useParams();
  /*=======================================
  =======================================*/
  

  const navigate = useNavigate();
  const defaultValues: any = {
    plan_id: plan_id? plan_id : "null",
    plan_name: "",
    plan_days: 0,
    plan_price: 0,
    user_display: 0,
  };
  

  const [formValues, setFormValues] = useState(defaultValues)

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const checkForm = (): boolean => {
    let valid = true;

    if (formValues.plan_name == "") {
      valid = false;
      enqueueSnackbar('Plan name is required', { variant: 'error' });
    }

    if (formValues.plan_days == 0) {
      valid = false;
      enqueueSnackbar('Plan days is required', { variant: 'error' });
    }

    return valid;
  }



  const handleSubmit = () => {
    if (!checkForm()) return;
    setLoading(true);
    
    axios.post(ProjectConfig.api_url, null,
      {
        params: {
          request: 'add_edit_plan',
          formdata: {
            ...formValues,
            user_display: formValues.user_display == 1 ? true : false,
          },
        }
      })
        .then((response) => {
          enqueueSnackbar('Plan saved successfully', { variant: 'success' });
          navigate('/plans', { replace: true });
          console.log("res",response.data);
        })
        .catch((err) => {
          console.log(err.data);
        }).
        finally(() => {
          setLoading(false)
        })
        
  };



  /**/

  useEffect(() => {
    if (plan_id) {
      axios
        .get(ProjectConfig.api_url, {
          params: {
            request: "plan_details",
            plan_id: plan_id,
          },
        })
        .then((response) => {
          console.log(response.data);
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    }
  }, []);

  return (
      <div id="plansEditor" className='ModuleContainer'>
          <Box id="project_editor" className="box_editor forced_box_editor" title="Plan" subtitle="Edit"  >

                <TextField 
                      id="plan_name"
                      name="plan_name"
                      label="Plan Name"
                      type="text"
                      required
                      fullWidth
                      value={formValues.plan_name}
                      onChange={handleInputChange}
                    />
                <div className='input_row'>
                   

                    <TextField
                      id="plan_days"
                      name="plan_days"
                      label="Plan Days"
                      type="number"
                      required
                      fullWidth
                      value={formValues.plan_days}
                      onChange={handleInputChange}
                    />

                    <TextField
                      id="plan_price"
                      name="plan_price"
                      label="Plan Price"
                      type="number"
                      required
                      fullWidth
                      value={formValues.plan_price}
                      onChange={handleInputChange}
                    />

                </div>

                <FormControlLabel
                  label={'Is for users?'}
                  sx={{
                    alignSelf: 'flex-end',
                  }}
                  control={
                    <Checkbox
                      checked={formValues.user_display == 1}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          user_display: e.target.checked ? 1 : 0,
                        });
                      }}
                    />
                  }
                />



            <div className='buttons'>
              <Button onClick={() => navigate(-1)} variant="outlined" className="button_2">Close</Button>
              <Button onClick={handleSubmit} variant="contained" className="button_1">Submit</Button>
            </div>
          </Box>
      </div>
  );
};
